<template>

    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 48 48">
        <g id="Layer_1">
	<path d="M11.9,28.5l8.8,0v3.3l-8.8,0c-4.2,0-7.8-3.3-7.9-7.5c-0.2-4.4,3.4-8.1,7.7-8.1h9v0c0,1.8-1.5,3.3-3.3,3.3h-5.7
		c-2.6,0-4.7,2.3-4.5,4.9C7.5,26.8,9.6,28.5,11.9,28.5z M36.1,16.2l-8.8,0v3.3l8.8,0c2.3,0,4.4,1.7,4.6,4.1c0.3,2.7-1.9,4.9-4.5,4.9
		h-5.7c-1.8,0-3.3,1.5-3.3,3.3v0h9c4.4,0,7.9-3.6,7.7-8.1C43.8,19.5,40.3,16.2,36.1,16.2z M15,25.6h18v-3.3H15V25.6z" />
</g>
        <g id="Layer_2">
</g>
</svg>

</template>

<script>
export default {
    name: 'linkIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
