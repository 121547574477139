<template>

    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M40.8 14.8l-7.9-7.9c-.5-.5-1.3-.8-2-.8H26 10c-2.2 0-4 1.8-4 4v28c0 2.2 1.8 4 4 4h24c0-2.2-1.8-4-4-4H10V18h16c2.2 0 4-1.8 4-4v-4l8 8v24h4V17.7c0-1.1-.4-2.1-1.2-2.9zM26 14H10v-4h16v4zm-2 6c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z" /></svg>

</template>

<script>
export default {
    name: 'SaveIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

