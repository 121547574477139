<template>
    <div class="form-group pt-0 closed-caption-upload-wrapper file-upload-vue-wrapper mb-0">
        <div v-if="update_allowed" class="text-center" :class="filename == '' ? 'show-upload-block' : 'show-preview'" @click.prevent="activateDropzone(machine_name, $event)">
            <div v-if="filename == ''">
                <a href="#" class="btn btn-primary displaybloc"><upload class="svg-24"></upload> Upload Image</a>
                <div class="image-description"><p>{{ description }}</p></div>
            </div>
            <div class="input-wrapper" v-show="update_allowed && filename == '' && showDropzone">
                <vue-dropzone class="video-placeholder" :ref="'dropzone_in_template'+machine_name" :id="'dropzone_in_template'+machine_name" :options="dropzoneOptions" v-on:vdropzone-success="successCallback"></vue-dropzone>
            </div>
            <div id="closed_caption" class="filename no-border d-flex justify-content-between align-items-center"  v-if="filename != '' && display_image_or_filename != 'image'" style="margin-left: -36px;">
                <div class="pr-3 d-flex align-items-center">
                    <file-icon class="mr-2"></file-icon>
                    <div>{{ filename }}</div>
                </div>
            </div>
            <div v-if="displayImage != '' && filename != '' && display_image_or_filename == 'image'">
                <div>
                    <img :src="displayImage">
                </div>
            </div>
        </div>
        <div v-else>
            <img :src="displayImage">
        </div>

    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import modal from './Modal.vue';
import upload from '../Icons/upload.vue';
import trash from '../Icons/trash.vue';
import FileIcon from "@/Icons/file.vue";

export default {
    components: {FileIcon, modal, vueDropzone, upload, trash },
    props: ['entry', 'file_types', 'saved_filename', 'description', 'required', 'machine_name', 'update_allowed', 'role', 'action_url','display_image_or_filename', 'image_width', 'image_height', 'image_width_only'],
    data() {
        return {
            filename: this.saved_filename,
            modalTriggerConfirmDelete: false,
            showDropzone: false,
            underlineFieldClass: '',
            displayImage: '',
            dropzoneOptions: {
                url: this.action_url,
                thumbnailWidth: 150,
                paramName: this.machine_name,
                acceptedFiles: this.file_types,
                maxFilesize: 30000, // 30GB
                timeout: 86400000, // 24hrs
                headers: { 'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content },
                complete: this.completeUploadShowImage,
                error: function(file, message) {
                    let errorMessage = message + ' There was a problem uploading your file.';
                    this.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    this.$scrollTo('#top-of-form', 700);
                }.bind(this),
                accept: this.acceptCallback
            }
        }
    },
    created() {
        if(this.entry[this.machine_name] != undefined) {
            this.displayImage = this.entry[this.machine_name];
        }
    },
    methods: {
        completeUploadShowImage: function(file) {
            if(this.display_image_or_filename === 'image') {
                this.displayImage = file.dataURL;
            }
            this.$emit('saveForValidation', this.filename, this.machine_name, this.displayImage);
        },
        activateDropzone: function(machine_name, $event) {
            let valCodeButton = document.getElementById('dropzone_in_template'+machine_name);
            valCodeButton.click();
        },
        activateDeleteCCFilePopup: function() {
            let app = this;
            app.modalTriggerConfirmDelete = true;
        },
        deleteVideo: function() {
            let app = this;
            window.axios(
                {
                    method: 'POST',
                    data: {
                        [app.machine_name]: '',
                    },
                    url: app.action_url,
                }).then(function(response) {
                app.filename = '';
                app.modalTriggerConfirmDelete = false;
                window.location.reload();
            }).catch(function (err) {
                let errorMessage = err.response.status + ' status error. There was a problem deleting your file.';
                app.$parent.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
            });
        },
        underlineFields() {
            let app = this;
            let submitFormBlock = true;
            // If required and filename is blank, don't submit the form!
            if((app.required == 1) && (app.filename == '')) {
                submitFormBlock = false;
                app.underlineFieldClass = ' required';
            }
            return submitFormBlock;
        },
        successCallback: function(file, response) {
            this.filename = file.upload.filename;
        },
        acceptCallback: function(file) {
            let app = this;
            this.$refs['dropzone_in_template'+app.machine_name].dropzone.on("thumbnail", function(file) {
                if (app.image_width_only && (file.width != app.image_width || file.height > app.image_height)) {
                    alert("Image dimensions incorrect. Image width must be " + app.image_width + " px wide. Max height " + app.image_height + " pixels.");
                    app.$refs['dropzone_in_template' + app.machine_name].removeAllFiles(true);
                    app.showDropzone = false;
                } else if (file.width != app.image_width && file.height != app.image_height) {
                    alert("Image dimensions incorrect. Image must be " + app.image_width + " x " + app.image_height + " pixels.");
                    app.$refs['dropzone_in_template' + app.machine_name].removeAllFiles(true);
                    app.showDropzone = false;
                } else {
                    app.$refs['dropzone_in_template'+app.machine_name].dropzone.processFile(file);
                }
            });
            this.showDropzone = true;
        }
    }
}
</script>
