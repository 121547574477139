<!--

  This component was copied from the membership project.
  When fixing bugs or adding features, consider updating the original component as well.
  Until we have a shared library, we need to keep the components in sync manually.

-->

<!--This is the phone number component that creates two fields for phone numbers-->
<!--It will apply the appropriate mask based on the first input (defaults to +1)-->

<!--A few notes-->
<!--1) To get it to work you need to create a temporary variable in your parent component-->
<!--eg: home_phone: this.user.home_phone ? this.user.home_phone.trim() : '' -->

<!--2) Before you save the data, you should ensure it's being assigned properly, before the axios call.-->
<!--eg:  this.userData.home_phone = this.home_phone-->

<!-- - These items are currently needed as we need to concat the fields for saving purposes. And if we-->
<!--return the concatenated item then the field in the parent component shows the concatenated data.-->
<!--&#45;&#45; Not ideal and room for improvement later-->
<!-- -&#45;&#45; JS - Nov 17 2022-->

<!-- Note - The element '<label class="d-none">off</label>' needs to be there so autofill doesn't fill in the
next field with incorrect values. Blurg -->

<template>
    <div :class="[inline ? 'row' : 'form-group row mb-0', formGroupWrapperClass]">
        <div class="col-12" v-if="label">
            <label>{{ label }} <sup v-if="requiredField">*</sup> </label>
        </div>
        <div class="col-2 pe-0 phctry-wrapper">
            <label class="d-none">off</label>
            <cleave :id="'phctry' + uniqueKey" autocomplete="off" @blur="phone_country_change('home_phone', 'home_phone_country')" :raw="false" :options="countryCode" v-model="home_phone_country" class="form-control" />
        </div>
        <div class="col-10 phctry-second-wrapper">
            <cleave @blur="emitData()" :raw="false" :options="initialPhoneNumberMaskFormat" :name="name" :id="name" v-model="home_phone" :required="requiredField" class="form-control" />
        </div>
        <div class="col-12">
            <div class="error-message" v-if="errorMessage">
                <p class="red">{{ errorMessage }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Cleave from "vue-cleave-component";

export default {
    name: "PhoneNumberInput",
    components: {Cleave},
    props: {
        requiredField: {
            type: Boolean,
            default: false
        },
        inline: Boolean,
        label: String,
        name: String,
        formGroupWrapperClass: String,
        id: String,
        modelValue: String,
        errorMessage: String,
        value: String,
        uniqueKey: {
            type: String,
            default: ''
        }
    },

    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            home_phone: this.modelValue ? this.modelValue.trim() : '',
            home_phone_country: '+1',
            countryCode: {
                delimiters: ["+",""],
                blocks: [0,5],
            },
            initialPhoneNumberMaskFormat: {
                numericOnly: true,
                blocks: [0, 3, 0, 3, 4],
                delimiters: ["(", ")", " ", "-"]
            },
            internationalMask: {
                numericOnly: true,
                blocks: [15]
            },
            northAmericaPhoneMask: {
                numericOnly: true,
                blocks: [0, 3, 0, 3, 4],
                delimiters: ["(", ")", " ", "-"]
            }
        }
    },
    created() {
        if(this.value) { // This deals with when we input a value from non-vue area (register.blade.php)
            this.home_phone = this.value ? this.value.trim() : ''
        }
        this.formatPhoneNumberInitial('home_phone', 'home_phone_country');
    },
    methods: {
        phone_country_change(phone_field, country_code_field) {
            if (this[country_code_field] !== '+1') {
                // International mask
                this.initialPhoneNumberMaskFormat = this.internationalMask
            } else {
                // North American mask
                this.initialPhoneNumberMaskFormat = this.northAmericaPhoneMask
            }
            this.emitData();
        },
        formatPhoneNumberInitial(phone_field, country_code_field) {
            // If length of phone number over 12 characters apply international mask.
            if(this[phone_field].replace(/\s/g, '').length > 13) {
                this.initialPhoneNumberMaskFormat = this.internationalMask
            }

            // If length of phone number starts with + apply international mask.
            // Then slice out the first space in the phone number. First group international / second reg #
            // If no space, just display the entire thing with a blank 'country code'
            if(this[phone_field].charAt(0) === '+') {
                this.initialPhoneNumberMaskFormat = this.internationalMask
                let firstSpace = this[phone_field].indexOf(' ');
                if(firstSpace !== -1) {
                    this[country_code_field] = this[phone_field].slice(0, firstSpace);
                    this[phone_field] = this[phone_field].slice(firstSpace);
                } else {
                    this[country_code_field] = '+';
                    this[phone_field] = this[phone_field].slice(1);
                }
            }
        },
        emitData() {
            // join country code & phone #. If country code blank, add a "+"
            let phone_number_concatenated = this.home_phone
            if(this.home_phone_country.trim() !== '+1') {
                phone_number_concatenated = (this.home_phone_country.trim() ? this.home_phone_country : '+') + ' ' + this.home_phone;
            }
            // We actually need to emit the concatenated value
            // But we can't do it on the actual value because then it's messed up in the input
            this.$emit('update:modelValue', phone_number_concatenated)
        }
    }
}
</script>

<style scoped>

</style>
