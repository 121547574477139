<template>
    <div class="mb-4 viewing-order-email-full-wrap" :id="'top-of-email-form-' + month">

        <div class="fullwidth pt-5" v-if="showMessage">
            <div class="flash-alert alert-danger mb-1">
                <div class="alert-header">
                    <attention class="white-svg mx-4"></attention>
                    <div class="message-wrapper">
                        <h4 class="mb-0 text-white">Fill in all the required fields before proceeding.</h4>
                    </div>
                    <span class="d-inline-block white-svg cursor-pointer flex-grow-1 text-end" @click.prevent="showMessage = false">
                    <circle-close-icon></circle-close-icon>
                </span>
                </div>
            </div>
        </div>

        <div v-if="!emailData && !createEmailFromApproved" @click.prevent="createEmail('new')" class="add-email-month cursor-pointer w-100 header-button position-relative d-flex justify-content-between accordion-button pl-30 pr-30 py-3 py-lg-2">
            <div class="w-100 header-button position-relative d-flex justify-content-between accordion-button py-3 py-lg-2">
                <div class="d-flex align-items-center justify-content-between w-100 app-step-h3-header accordion-header">
                    <h3 class="mb-0">{{ month }}</h3>
                    <div class="d-flex align-items-center w-25 justify-content-end">
                        <div class="d-flex align-items-center pr-2">
                            <span class="text-uppercase mr-2 accent">Schedule Email</span> <plus-circle-icon class="svg-accent"></plus-circle-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-else class="email-accordion-wrapper accordion-header position-relative cursor-pointer d-flex justify-content-between" :class="!active ? 'no-hover no-click' : ''">
            <a @click.prevent="accordionToggle()" class="w-100 header-button position-relative d-flex justify-content-between accordion-button pl-30 pr-30 py-3 py-lg-2">
                <div class="d-flex align-items-center justify-content-between w-100 app-step-h3-header">
                    <h3 class="mb-0">{{ month }}</h3>
                    <div class="d-flex align-items-center w-25 justify-content-end">
                        <div class="d-flex align-items-center cursor-initial" v-if="emailData" :class="'pr-2 status-color-' + emailData.email_status">
                            <submitted svg-class="svg-24 mr-2" v-if="emailData.email_status == 'submitted'"></submitted>
                            <question svg-class="svg-24 mr-2" v-if="emailData.email_status == 'need_more_info'"></question>
                            <circle_check svg-class="svg-24 mr-2" v-if="emailData.email_status == 'approved'"></circle_check>
                            <span class="text-uppercase" v-if="emailData.email_status">{{ emailData.email_status.replace(/_/g, ' ') }}</span>
                        </div>
                        <up-chevron-icon class="svg-accent" :class="(accordionStatus) ? 'rotate-0' : 'rotate-180'"></up-chevron-icon>
                    </div>
                </div>
            </a>
        </div>
        <div id="accordion-order-details" class="pt-3 pb-3 border-radius-0 card-bg-white text-bg-light accordion-collapse collapse" :class="(accordionStatus) ? 'show' : ''">
            <div v-if="!createEmailFromApproved && emailData" class="pl-30 pr-30" :class="updateAllowed['assets'] ? 'accordion-editable' : 'accordion-readonly'">
                <div class="admin-form-status d-flex justify-content-between" v-if="role == 'admin'">
                    <div class="d-flex align-items-center">
                        <label for="billing_address_state" class="no-border pl-0 mr-3">Email Status:</label>
                        <div class="w-400px">
                            <v-select @input="changeEmailStatus" :reduce="emailStatusOptions => emailStatusOptions.code" id="email_status" placeholder="Select option" v-model="emailData.email_status" :options="emailStatusOptions" :clearable="false"></v-select>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-primary" @click.prevent="updateForm('','',true)">Admin Save</button>
                    </div>
                </div>
                <div class="admin-form-status d-flex justify-content-between pt-3" v-if="role == 'admin' && emailData.needs_more_info_text">
                    <div class="d-flex align-items-center">
                        <label for="billing_address_state" class="no-border pl-0 mr-3">Needs More Info:</label>
                    </div>
                    <div class="col-10 col">{{ emailData.needs_more_info_text }}</div>
                </div>
                <div class="col-10 pl-0 red" v-else-if="emailData.needs_more_info_text && emailData.email_status == 'need_more_info'">{{ emailData.needs_more_info_text }}</div>

                <div class="form-calendar form-group-beside row" :class="errorObject['assets'] && errorObject['assets']['send_at'] ? 'required' : ''">
                    <label :for="month+'send_at'" class="col-2 col-md-6">
                        <div>Send Date: <em class="yellow no-style">*</em></div>
                        <div class="description">
                            Email send dates are on a first-come, first-served basis.
                            Dates that are greyed out have reached the maximum email sends allotted for that day.
                            Emails are sent Monday - Friday. Dates are subject to change by Television Academy if assets are late.
                        </div>
                    </label>
                    <div class="col-10 col-md-6 input-wrapper input-wrapper-date">
                        <calendar-icon data-toggle class="svg-18 svg-accent d-inline-block" :class="(updateAllowed['assets']) ? '':'mt-2'"></calendar-icon>
                        <flat-pickr @on-change="updateForm('assets','send_at',false)" :id="month+'send_at'" v-model="emailData.send_at" :config="dateConfigEmails"
                                    placeholder="Select date"  v-if="updateAllowed['assets']"></flat-pickr>
                        <span class="readonly-date" v-text="emailData.send_at" v-else></span>
                        <div class="input-group-append top-0 position-absolute" v-if="role == 'admin' && emailData.send_at && emailData.send_at != ''">
                            <a href="#" @click.prevent="emailData.send_at = ''">
                                <close-icon class="svg-accent"></close-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="form-group-beside row">
                    <label for="subject_line" class="col-2 col-md-6">Subject Line: <em class="yellow no-style">*</em></label>
                    <div class="col-10 col-md-6 input-wrapper">
                        <span v-if="role != 'admin'" class="input-line-height">{{ emailData.subject }}</span>
                        <input v-else v-model="emailData.subject" type="text" id="subject_line" class="form-control subject_line">
                    </div>
                </div>

                <div class="row radio-wrapper form-group-beside" :class="errorObject['assets'] && errorObject['assets']['background_color'] ? 'required' : ''">
                    <div class="col-2 col-md-6 faked-label radio-button-wrapper-forspacing mb-0 pb-0">
                        Invitation Style? <em class="yellow no-style">*</em>
                    </div>
                    <div class="col-10 col-md-4 radio-button-content ml-4 px-0 d-flex justify-content-between align-items-center" :class="updateAllowed['assets'] ? 'pl-4' : ''">
                        <div class="custom-control custom-radio d-flex align-items-center pl-0">
                            <input v-if="updateAllowed['assets']" @change="updateForm('assets','background_color',false)" type="radio" v-model="emailData.background_color" value="0" class="custom-control-input" :id="month + 'orderinfo_emails_background_color_light'" :name="month + 'orderinfo_background_color'">
                            <div v-else>
                                <circle-filled-icon v-if="emailData.background_color == '0'"></circle-filled-icon>
                                <circle_icon v-else></circle_icon>
                            </div>
                            <label class="custom-control-label no-border pl-1" :class="updateAllowed['assets'] ? '' : 'no-click'" :for="month + 'orderinfo_emails_background_color_light'">Light Style</label>
                        </div>

                        <div class="custom-control custom-radio d-flex align-items-center pl-0">
                            <input v-if="updateAllowed['assets']" @change="updateForm('assets','background_color',false)" type="radio" v-model="emailData.background_color" value="1" class="custom-control-input" :id="month + 'orderinfo_emails_background_color_dark'" :name="month + 'orderinfo_background_color'">
                            <div v-else>
                                <circle-filled-icon v-if="emailData.background_color == '1'"></circle-filled-icon>
                                <circle_icon v-else></circle_icon>
                            </div>
                            <label class="custom-control-label no-border pl-1" :class="updateAllowed['assets'] ? '' : 'no-click'" :for="month + 'orderinfo_emails_background_color_dark'">Dark Style</label>
                        </div>
                    </div>
                </div>

                <div class="asset-preview-block full-email-wrapper-preview-block row no-gutters mt-5">
                    <div class="col-12 d-flex align-items-start">
                        <div class="background-wrapper mr-4" :class="emailData['email_image'] == '' ? 'no-image-uploaded' : ''">
                            <div class="email-wrapper" :class="emailData.background_color == 1 ? 'dark-style' : 'light-style'">
                                <div class="email-header text-center fyc-vp-email-header">
                                    <h2 class="text-uppercase">For your consideration</h2>
                                </div>
                                <div class="file-upload-section" @click="$refs[month+'_email_image_uploader'].uploadFileClick()">
                                    <div v-if="savingEmail && preview_saving" class="show-upload-block form-group pt-0 closed-caption-upload-wrapper file-upload-vue-wrapper mb-0 flex-column">
                                        <div class="spinner large"></div>
                                    </div>
                                    <img :src="emailData.email_image" v-else-if="emailData.email_image != ''">
                                    <div v-else-if="emailData.email_image == ''" class="show-upload-block form-group pt-0 closed-caption-upload-wrapper file-upload-vue-wrapper mb-0 flex-column">
                                        <button><upload class="svg-24"></upload> Upload Image</button>
                                        <div class="image-description">
                                            <p>Must be 650 px wide. Max height 800 pixels</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-white description wysiwyg-content pt-30 pb-30">
                                    <p v-if="order_type == 'vp'">This is being presented on the Television Academy’s <a href="https://view.televisionacademy" target="_blank" class="no-click no-hover" @click.prevent="">Viewing Platform</a>.</p>
                                    <p v-else>
                                        To watch Emmy®-eligible programs from {{ ((orderinfo.cable_network != null) ? (orderinfo.cable_network == 'Other' ? orderinfo.cable_network_other : orderinfo.cable_network) : '[NETWORK]') }},
                                        <template v-if="order_type == 'vp'">visit the link below</template>
                                        <template v-else>use your personalized viewing code at the link below:</template>
                                    </p>
                                    <p class="font-weight-bold mb-0" v-if="order_type == 'network' && orderinfo.is_code_unique == '0' && orderinfo.network_access_code">{{ orderinfo.network_access_code }}</p>
                                    <p class="font-weight-bold mb-0" v-if="order_type == 'network' && orderinfo.is_code_unique == '1'">[unique code will appear here]</p>
                                </div>
                                <div class="my-2 text-center event_code_in_email font-weight-bold"><a href="" class="btn btn-primary f-16 btn-no-hover no-click" @click.prevent="">Click to view</a></div>
                                <div class="mt-2 mb-0 text-center event_code_in_email pt-3">or visit</div>

                                <div v-if="order_type == 'vp'" class="mb-2 text-center event_code_in_email font-weight-bold"><a href="https://view.televisionacademy.com" class="cursor-initial no-click" @click.prevent="">view.televisionacademy.com</a></div>
                                <div v-else class="mb-2 text-center event_code_in_email font-weight-bold"><a :href="orderinfo.url" class="cursor-initial no-click no-hover" @click.prevent="">
                                    {{ orderinfo.url_to_display != null ? orderinfo.url_to_display : orderinfo.url }}
                                </a></div>

                                <div class="text-center event_code_in_email pt-4 mb-0">
                                    Supported platforms:
                                </div>
                                <div class="text-white description wysiwyg-content pt-0 pb-3" v-if="order_type == 'vp'">
                                    <p>iOS, iPadOS, Apple TV, Android, Android TV, Roku, Amazon Fire/Fire TV and Web</p>
                                </div>
                                <div class="text-white description wysiwyg-content pt-0 pb-3" v-else>
                                    <p>{{ format_platform_content(orderinfo.platform) }} </p>
                                </div>

                                <div class="email-footer text-center">
                                    <div class="footer-section">
                                        <div class="email-footer-questions pb-4 pt-0">
                                            <p class="mb-0">Questions? Visit the <a href="https://televisionacademy.com/faq/fyc" target="_blank" class="no-hover no-click" @click.prevent="">FYC FAQ</a> for answers, or contact <a href="mailto:fyc@televisionacademy.com" @click.prevent="" class="no-click no-hover">fyc@televisionacademy.com</a>.</p>
                                        </div>
                                        <div class="vp-email-wrapper">
                                            <p v-if="order_type == 'network'">The access code, which expires {{ viewing_platform_expiry_date }}, is provided solely for your use.</p>

                                            <p class="mb-0">
                                                FYC viewing content is the copyrighted property of the networks,
                                                studios, and production entities who supply them and is made available for
                                                your personal consideration only during the current Emmy® Awards season.
                                                Please note, only members who have National Active/Voting membership
                                                status are eligible to vote in the Emmy® Awards competition.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="email-header text-center fyc-vp-email-header" v-if="order_type == 'network'">
                                    <h2 class="text-uppercase px-3 mb-0">
                                        This viewing access is presented and hosted by {{ (orderinfo.cable_network_all_caps != null ? orderinfo.cable_network_all_caps : (orderinfo.cable_network != null) ? (orderinfo.cable_network == 'Other' ? orderinfo.cable_network_other : orderinfo.cable_network) : '[NETWORK]') }}, not by the Television Academy.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div class="right-side-spacing" :class="errorObject['assets'] && errorObject['assets']['email_image'] ? 'text-only-red required' : ''">
                            <h3 class="order-header mb-2">Email Preview</h3>
                            <p class="f-16 inactive-grey font-italic mt-5" v-if="order_type == 'network'">To change the URL address or the supported platforms, you must revise the appropriate fields in the Order Details section. Please contact FYC@TelevisionAcademy.com if you need to make edits. Subject line cannot be changed.</p>
                            <button v-if="emailData.email_image != '' && updateAllowed['assets']" @click.prevent="deleteEmailImageModal = true" class="btn btn-delete mb-3">
                                <trash-icon></trash-icon> Delete Image
                            </button>
                            <a :href="'/order/email/' + emailData.id +'/download'" v-if="emailData.email_image != '' && updateAllowed['assets']" class="btn btn-primary">
                                <download-icon></download-icon> Download Image
                            </a>
                        </div>
                    </div>
                </div>
                <fyc-cropper
                    :ref="month+'_email_image_uploader'"
                    v-if="updateAllowed['assets']"
                    indexval="11"
                    :initval="emailData.email_image"
                    machine_name="email_image"
                    min-crop-width="650"
                    min-crop-height="300"
                    max-crop-height="800"
                    :file-size-restriction="true"
                    :aspectRatio="false"
                    :hasAspectRatio="false"
                    @deleteImage="deleteImageEmail('email_image')"
                    @croppedImage="saveForValidation"
                    :hide_actionable_area="true"
                    :updating-form="savingEmail"
                    :role="role"
                    :allowed_filetypes="['image/png, image/jpg, image/jpeg', 'image/gif']"
                ></fyc-cropper>
            </div>
            <div v-else class="pl-30 pr-30">

                <div class="mb-5 form-calendar form-group-beside row" :class="error_placeholder_send_at ? 'required' : ''">
                    <label :for="month+'send_at'" class="col-2 col-md-6">
                        <div>Send Date: <em class="yellow no-style">*</em></div>
                    </label>
                    <div class="col-10 col-md-6 input-wrapper input-wrapper-date">
                        <calendar-icon data-toggle class="svg-18 svg-accent d-inline-block" :class="(updateAllowed['assets']) ? '':'mt-2'"></calendar-icon>
                        <flat-pickr :id="month+'send_at'" v-model="placeholder_send_at" :config="dateConfigEmails"
                                    placeholder="Select date"  v-if="updateAllowed['assets']"></flat-pickr>
                        <span class="readonly-date" v-text="placeholder_send_at" v-else></span>
                        <div class="input-group-append top-0">
                            <a class="date-clear-button" type="button" title="Clear" data-clear v-if="updateAllowed['assets']">
                                <close-icon></close-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="d-flex mb-5 align-items-center">
                    <a @click.prevent="resendApprovedEmailModal()" class="btn btn-primary mr-4">Resend Approved Email</a>
                    <div>
                        <p class="mb-0">Resending your previous email uses all images and styles. This option does not require resubmission for review.</p>
                        <p class="red mb-0" v-if="error_placeholder_send_at">Please select a send date before using the 'resend previous email' functionality.</p>
                    </div>
                </div>

                <div class="d-flex mb-4 align-items-center">
                    <a @click.prevent="createEmail('start_new_email')" class="btn btn-primary mr-4">Start New Email</a>
                    <div>
                        <p class="mb-0">Starting a new template starts with no images or styles. This option requires submission for review.</p>
                    </div>
                </div>

            </div>
            <div v-if="updateAllowed['assets'] && emailData" class="pl-30 pr-30 email-form-submit-actions d-flex justify-content-between align-items-center mt-5 pt-5 mb-4">
                <button v-if="update_allowed['assets']" class="btn btn-cancel btn-black" @click.prevent="deleteEmailModal = true"><delete-icon class="svg-accent svg-15"></delete-icon> Delete Email</button>
                <button v-if="update_allowed['assets'] && role != 'admin'" :disabled="(!savingEmail && emailData.email_image) ? false : true" class="btn btn-primary" @click.prevent="approveEmail()">Approve and Submit For Review</button>
                <button v-if="role=='admin'" class="btn btn-primary" @click.prevent="updateForm('','',true)">Admin Save</button>
            </div>
        </div>

        <modal v-if="approveEmailModal" @close="approveEmailModal = false">
            <h3 slot="header">Submit Email</h3>
            <div class="content" slot="body">
                <p>Please click below to submit for approval. Upon submission the Television Academy FYC team will review your assets.</p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn-black" @click="approveEmailModal = false">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="submitEmail()">Submit Email</button>
            </div>
        </modal>

        <modal v-if="deleteEmailModal" @close="deleteEmailModal = false">
            <h3 slot="header">Delete Email</h3>
            <div class="content" slot="body">
                <p>Are you sure you want to delete this email?</p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn-black" @click="deleteEmailModal = false">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="deleteEmail()">Yes, Delete</button>
            </div>
        </modal>

        <modal v-if="deleteEmailImageModal" @close="deleteEmailImageModal = false">
            <h3 slot="header">Remove Image</h3>
            <div class="content" slot="body">
                <p>Are you sure you want to remove this image?</p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn-black" @click="deleteEmailImageModal = false">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="deleteImageEmail()">Yes, Delete</button>
            </div>
        </modal>


        <modal v-if="selectApprovedEmailMonth" @close="selectApprovedEmailMonth = false">
            <div slot="header" class="d-flex justify-content-between align-items-center w-100">
                <h3 class="mb-0">Approve Email</h3>
                <a class="cursor-pointer" href="" @click.prevent="selectApprovedEmailMonth = false">
                    <close-icon></close-icon>
                </a>
            </div>
            <div class="content" slot="body">
                <p>Select an email from below. This will approve the email and bypass review.</p>
                <div v-for="emailMonth in approved_emails" class="my-3 text-center">
                    <a href="" class="cursor-pointer btn btn-primary w-130minpx" @click.prevent="cloneEmail(emailMonth)">{{ emailMonth }}</a>
                </div>
            </div>
            <div class="form-group-submit d-none" slot="footer">
                <button class="pull-left btn-black" @click="selectApprovedEmailMonth = false">Close</button>
            </div>
        </modal>

        <modal v-if="role == 'admin' && modalNeedsMoreInfo" @close="modalNeedsMoreInfo = false">
            <h3 slot="header">Needs More Information</h3>
            <div class="content" slot="body">
                <textarea v-model="emailData.needs_more_info_text" placeholder="Enter the message that will be emailed to the Submitter."></textarea>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn-black" @click.prevent="closeSendNeedsMoreInfoModal()">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="sendNeedsMoreInfo()">Send Email</button>
            </div>
        </modal>

    </div>
</template>

<script>
import Attention from "@/Icons/attention.vue";
import Checkmark from "@/Icons/checkmark.vue";
import Submitted from "@/Icons/submitted.vue";
import Question from "@/Icons/question.vue";
import Circle_check from "@/Icons/circle_check.vue";
import UpChevronIcon from "@/Icons/up-chevron.vue";
import MinusLarge from "@/Icons/minus-large.vue";
import DeleteIcon from "@/Icons/delete.vue";
import PlusCircleIcon from "@/Icons/plus-circle.vue";
import Modal from "@/components/Modal.vue";
import CalendarIcon from "@/Icons/calendar.vue";
import CircleCloseIcon from "@/Icons/circle_close.vue";
import circle_icon from "@/Icons/circle_icon.vue";
import CircleFilledIcon from "@/Icons/circle_filled.vue";
import Upload from "@/Icons/upload.vue";
import TrashIcon from "@/Icons/trash.vue";
import CloseIcon from "@/Icons/close.vue";

export default {
    name: "OrderVpEmail",
    components: {
        CloseIcon,
        TrashIcon,
        Upload,
        circle_icon,
        CircleCloseIcon,
        CalendarIcon,
        PlusCircleIcon,
        DeleteIcon,
        MinusLarge,
        UpChevronIcon,
        Circle_check,
        Question,
        Submitted,
        Checkmark,
        Attention,
        Modal,
        CircleFilledIcon,
    },
    props: {
        month: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
        },
        email_statuses: {
            type: Array,
            required: true,
        },
        orderinfo: {
            type: Array,
            required: true,
        },
        update_allowed: {
            type: Array,
            required: true,
        },
        role: {
            type: String,
        },
        viewing_platform_expiry_date: {
            type: String
        },
        order_type: {
            type: String,
            default: 'vp',
        },
        excluded_dates: {
            type: Array
        }
    },
    data() {
        return {
            showMessage: false,
            statusForIndicatorsAssets: ['need_more_info', 'submitted', 'approved', 'building', 'published'],
            allowedStatusForEditing: ['need_more_info', 'in_progress'],
            accordionStatus: false,
            deleteEmailModal: false,
            deleteEmailImageModal: false,
            approveEmailModal: false,
            modalNeedsMoreInfo: false,
            createEmailFromApproved: false,
            errorCodes: [401, 403, 419],
            errorObject: {
                'details': {},
                'assets': {},
                'post_event': {}
            },
            savingEmail: false,
            preview_saving: false,
            emailData: '',
            emailOriginalStatus: '',
            dateConfigEmails: {
                wrap: true,
                dateFormat: 'm-d-Y',
                minDate: '01-01-2020',
                maxDate: '01-01-2025',
                disable: this.excluded_dates
            },
            updateAllowed: {
                'details': false,
                'assets': false,
                'post_event': false,
            },
            emailStatusOptions: [],
            approved_emails: [],
            placeholder_send_at: '',
            error_placeholder_send_at: false,
            selectApprovedEmailMonth: false,
            startMonthDate: {
                November: '11-01-2024',
                December: '12-01-2024',
                March: '03-04-2024',
                April: '04-01-2024',
                May: '05-01-2024',
                June: '06-01-2024',
            },
            endMonthDate: {
                November: '11-30-2024',
                December: '12-31-2024',
                March: '03-31-2024',
                April: '04-30-2024',
                May: '05-31-2024',
                June: '06-12-2024',
            }
        }
    },
    mounted() {
        let app = this;
        // Filter orderinfo.emails to only return the month of April
        app.emailData = app.orderinfo.emails.filter(function (item, index) {
            return item.month == app.month;
        })[0];

        // Create proper email status options
        let dataKeys = Object.entries(app.email_statuses)
        app.emailStatusOptions = dataKeys.map(function (item, index) {
            return {
                'code': item[0],
                'label': item[1]
            }
        });

        if(app.emailData) {
            app.editingAllowedChecker();
            app.emailOriginalStatus = app.emailData.email_status;
        }

        this.dateConfigEmails.minDate = this.startMonthDate[this.month]
        this.dateConfigEmails.maxDate = this.endMonthDate[this.month]
    },
    methods: {
        accordionToggle() {
            this.accordionStatus = !this.accordionStatus;
        },
        resendApprovedEmailModal() {
            if(this.placeholder_send_at == '') {
                this.error_placeholder_send_at = true;
                return;
            }
            this.selectApprovedEmailMonth = true
        },
        editingAllowedChecker() {
            this.updateAllowed['assets'] = true;
            if(this.role == 'admin') {
                return;
            }
            if(this.emailData && !this.createEmailFromApproved) {
                this.updateAllowed['assets'] = this.allowedStatusForEditing.includes(this.emailData.email_status);
            }
            if(!this.active) {
                this.updateAllowed['assets'] = false;
            }
        },
        createEmail(type) {
            let app = this;
            if(app.savingEmail) {
                return;
            }
            app.savingEmail = true;

            axios.post('/order/email/'+ this.orderinfo.order_id +'/create', {
                'month': app.month,
                'type': type,
                'send_at': app.placeholder_send_at,
            }).then(function(response) {

                if(response.data.status == 'approved_emails') {
                    app.createEmailFromApproved = true;
                    app.approved_emails = response.data.email_months;
                } else {
                    app.createEmailFromApproved = false;
                    app.emailData = response.data.email_data;
                }

                app.accordionStatus = true;
                app.savingEmail = false;

                app.editingAllowedChecker();
                app.$emit('hasEmailStarted', app.emailData);

            }).catch(function (err) {
                if(err.response && app.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }

                let errorMessage = err.response.data.message;
                app.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                app.savingEmail = false;
            });
        },
        cloneEmail: function(monthToClone) {
            let app = this;
            if(app.savingEmail) {
                return;
            }
            app.savingEmail = true;

            axios.post('/order/email/'+ this.orderinfo.order_id +'/clone', {
                'month': app.month,
                'month_to_clone': monthToClone,
                'send_at': app.placeholder_send_at,
            }).then(function(response) {
                window.location.reload();
            }).catch(function (err) {
                if(err.response && app.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }

                let errorMessage = err.response.data.message;
                app.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                app.savingEmail = false;
            });
        },
        updateForm: function(field_group, field_name, adminSave) {
            let that = this;
            if((that.role === 'admin') && (adminSave !== true)) {
                return false;
            }
            if(that.savingEmail) {
                return;
            }
            that.savingEmail = true;

            // Remove required field 'required' indicator if field is filled in
            if(field_group && field_name) {
                that.errorObject[field_group][field_name] = '';
            }

            window.axios(
                {
                    method: 'put',
                    url: '/order/email/' + that.emailData.id,
                    data: that.emailData,
                }).then(function(response) {
                let dataBack = response.data;
                that.excluded_dates = dataBack.excluded_dates;
                that.dateConfigEmails.disable = dataBack.excluded_dates;
                if((that.role === 'admin') || (adminSave)) {
                    location.reload();
                }
                that.deleteEmailImageModal = false;
                that.savingEmail = false;
                that.preview_saving = false;
            }).catch(function (err) {
                if(err.response && that.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }
                if (err.response) {
                    let errorMessage = err.response.data.message;
                    that.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    that.$emit('showflashalert');
                    that.$scrollTo('#top-of-form', 700);
                }
                that.savingEmail = false;
            });
        },
        approveEmail: function() {
            let that = this;
            if(that.savingEmail) {
                return;
            }
            that.savingEmail = true;

            window.axios(
                {
                    method: 'put',
                    url: '/order/email/' + that.emailData.id + '/validate',
                    data: that.emailData
                }).then(function(response) {
                    if(response.data.errors) {
                        that.errorObject = response.data.errors;
                    }
                    // Asset Details and/or Order Details complete, no errors get them to submit.
                    if(_.size(that.errorObject.assets) == 0) {
                        that.approveEmailModal = true;
                    } else {
                        // create custom error messaging for these emails here.
                        that.showMessage = true;
                        that.$scrollTo('#top-of-email-form-' + that.month, 700);
                    }
                    that.savingEmail = false;
            }).catch(function (err) {
                if(err.response && that.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }
                if (err.response) {
                    let errorMessage = err.response.data.message;
                    that.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    that.$emit('showflashalert');
                    that.$scrollTo('#top-of-email-form-' + that.month, 700);
                }
                that.savingEmail = false;
            })
        },
        submitEmail: function() {
            let that = this;
            if(that.savingEmail) {
                return;
            }
            that.savingEmail = true;

            window.axios(
                {
                    method: 'put',
                    url: '/order/email/' + that.emailData.id + '/submit',
                    data: that.emailData
                }).then(function(response) {
                    that.emailData = response.data.email_data;
                    that.approveEmailModal = false;
                    that.accordionStatus = false;
                    that.editingAllowedChecker();
                    that.showMessage = false;
                    that.savingEmail = false;
                    that.$emit('submitEmail', that.emailData.month);
            }).catch(function (err) {
                if(err.response && that.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }
                let errorMessage = err.response.data.message;
                that.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                that.$emit('showflashalert');
                that.$scrollTo('#top-of-form', 700);
                that.approveEmailModal = false;
            })
        },
        deleteEmail: function() {
            let that = this;
            if(that.savingEmail) {
                return;
            }
            that.savingEmail = true;

            window.axios(
                {
                    method: 'delete',
                    url: '/order/email/' + that.emailData.id,
                    data: that.emailData
                }).then(function(response) {
                window.location.reload();
            }).catch(function (err) {
                if(err.response && that.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }
                that.savingEmail = false;
            })
        },
        deleteImageEmail: function() {
            this.emailData.email_image = '';
            this.preview_saving = true;
            this.updateForm('assets', 'email_image', false);
            if(this.role == 'admin') {
                this.deleteEmailImageModal = false;
            }
        },
        saveForValidation: function(filename, field_name, fileData) {
            let that = this;
            this.emailData[field_name] = fileData;
            this.preview_saving = true;
            this.updateForm('assets', field_name, false);
        },
        saveImage: function() {
            let that = this;
            if(that.savingEmail) {
                return;
            }
            that.savingEmail = true;

            window.axios(
                {
                    method: 'put',
                    url: '/order/email/' + that.emailData.id + '/save-image',
                    data: that.emailData
                }).then(function(response) {
                that.emailData = response.data.email_data;
                that.editingAllowedChecker();
                that.savingEmail = false;
                that.preview_saving = false;
            }).catch(function (err) {
                if(err.response && that.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }
                that.savingEmail = false;
            })
        },
        format_platform_content: function(platforms) {
            let platform_content = '';
            if(platforms) {
                platforms.forEach(function(platform, index) {
                    platform_content += platform;
                    if(index < platforms.length - 1) {
                        platform_content += ', ';
                    }
                });
            }
            return platform_content;
        },
        changeEmailStatus: function($event) {
            if(this.emailData.email_status == 'need_more_info') {
                this.modalNeedsMoreInfo = true;
            } else {
                this.updateForm('','',true);
            }
        },
        closeSendNeedsMoreInfoModal: function() {
            this.emailData.email_status = this.emailOriginalStatus;
            this.modalNeedsMoreInfo = false;
        },
        sendNeedsMoreInfo: function() {
            let that = this;
            window.axios(
                {
                    method: 'put',
                    url: '/order/email/' + that.emailData.id,
                    data: that.emailData,
                }).then((response) => {
                    let dataBack = response.data;
                    location.reload();
                });
        },
    }
}
</script>
