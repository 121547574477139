<template>
    <svg :class="svgClass" viewBox="0 0 98 98" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon/Download" transform="translate(-26.000000, -26.000000)" fill="#f1ad14">
                <path d="M82.5811,85.5356 L82.5811,92.0406 L99.8721,75.3086 L99.8721,75.3086 C96.9691,72.3396 92.2151,72.2656 89.2201,75.1436 L85.4401,78.7766 C83.6121,80.5506 82.5811,82.9886 82.5811,85.5356 M49.6281,75.3086 L70.9581,96.1646 C73.0601,98.2196 76.4221,98.2006 78.5001,96.1226 L78.5001,49.6276 L71.0001,49.6276 L71.0001,85.7166 L60.2341,75.1906 C57.2731,72.2936 52.5241,72.3476 49.6281,75.3086 M74.7501,115.9996 C85.7691,115.9996 96.1271,111.7096 103.9181,103.9186 C111.7091,96.1266 116.0001,85.7676 116.0001,74.7496 C116.0001,63.7316 111.7091,53.3726 103.9181,45.5816 C96.1271,37.7906 85.7691,33.4996 74.7501,33.4996 C63.7311,33.4996 53.3731,37.7906 45.5821,45.5816 C37.7911,53.3726 33.5001,63.7316 33.5001,74.7496 C33.5001,85.7676 37.7911,96.1266 45.5821,103.9176 L45.5821,103.9186 C53.3731,111.7096 63.7311,115.9996 74.7501,115.9996 M74.7501,25.9996 C87.7711,25.9996 100.0141,31.0706 109.2211,40.2786 C118.4291,49.4866 123.5001,61.7286 123.5001,74.7496 C123.5001,87.7716 118.4291,100.0136 109.2211,109.2216 C100.0141,118.4296 87.7711,123.4996 74.7501,123.4996 C61.7281,123.4996 49.4861,118.4296 40.2791,109.2216 L40.2791,109.2216 C31.0711,100.0136 26.0001,87.7716 26.0001,74.7496 C26.0001,61.7286 31.0711,49.4866 40.2791,40.2786 C49.4861,31.0706 61.7281,25.9996 74.7501,25.9996" id="Fill-1"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'download',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
