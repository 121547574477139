<template>
    <div>
        <div class="col-12 border-bottom mb-4 pl-0 top-header-sticky">
            <div class="d-flex justify-content-between align-items-end pb-1">
                <h1 class="mb-0">Invoice {{ invoice.invoice_number }}</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-3">
                <div class="py-3">
                    <div class="label small-caps">Order</div>
                    <div class="value">
                        <template v-if="linkToSubject">
                            <a :href="linkToSubject">{{ subject.order_id }}</a>
                        </template>
                        <template v-else>
                            {{ subject.order_id }}
                        </template>
                    </div>
                </div>

                <div class="py-3">
                    <div class="label small-caps">Billing Contact</div>
                    <div class="value">
                        <template v-if="invoice.contact?.name">
                            {{ invoice.contact?.name }}<br>
                        </template>
                        {{ invoice.email }}
                    </div>
                </div>
            </div>
            <div class="col-3">
            </div>
            <div class="col-3">
                <div class="py-3">
                    <div class="label small-caps">Invoice Status</div>
                    <div class="value capitalize">
                        {{ invoice.status }}
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="py-3">
                    <div class="label small-caps">Invoice Number</div>
                    <div class="value">
                        {{ invoice.invoice_number }}
                    </div>
                </div>
                <div class="py-3">
                    <div class="label small-caps">Invoice Date</div>
                    <div class="value">
                        <date-picker v-model="invoiceDate" value-type="format"></date-picker>
                    </div>
                </div>
                <div class="py-3">
                    <div class="label small-caps">Payment Due</div>
                    <div class="value">
                        <date-picker v-model="paymentDueDate" value-type="format"></date-picker>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-3">
            <table>
                <tr>
                    <th v-if="invoice.subject_type === 'App\\Models\\Event'">Code</th>
                    <th>Product</th>
                    <th>Description</th>
                    <th>Amount</th>
                    <th v-if="productsCanBeEdited">Actions</th>
                </tr>
                <tr v-for="(item, key) in invoice.invoice_items">
                    <td v-if="invoice.subject_type === 'App\\Models\\Event'">{{ item.invoiceable.code }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ formatMoney(item.total) }}</td>
                    <td v-if="productsCanBeEdited">
                        <button type="button" class="btn-secondary p-2" @click="removeInvoiceItem(item, key)">
                            <i class="fas fa-minus-circle pr-2"></i> Remove
                        </button>
                    </td>
                </tr>
            </table>

            <template v-if="productsCanBeEdited">
                <button type="button" class="btn btn-primary p-2" @click="addProductModal = true">
                    <plus-circle-icon/>
                    Add Product
                </button>
            </template>

            <modal v-if="addProductModal" @close="addProductModal = false">
                <h2 slot="header">Add item to invoice</h2>
                <div class="content" slot="body">
                    <label class="mt-3">Product</label>
                    <select v-model="productToAdd" class="form-control"
                            @change="invoiceItemPrice = productToAdd.default_price">
                        <option v-for="product in products" :value="product">{{ product.name }}</option>
                    </select>

                    <label class="mt-3">Description</label>
                    <input type="text" v-model="invoiceItemDescription" class="form-control">

                    <label class="mt-3">Price</label>
                    <input type="text" v-model="invoiceItemPrice" class="form-control">
                </div>
                <div class="form-group-submit" slot="footer">
                    <button class="pull-left btn-black" @click="addProductModal = false">Close</button>
                    <button class="btn btn-primary pull-right" @click.prevent="addInvoiceItem">Add</button>
                </div>
            </modal>
        </div>

        <div class="row">
            <div class="col-8">
                <div class="py-3 mt-3">
                    <div class="label small-caps">Invoice Note</div>
                    <div class="value" style="white-space: pre-line">
                        <template v-if="noteEditingMode">
                            <textarea v-model="invoiceNotes" class="form-control mt-2"></textarea>
                        </template>
                        <template v-else-if="invoice.notes">
                            {{ invoice.notes }}
                        </template>
                        <template v-else>
                            <em>&mdash;</em>
                        </template>
                    </div>
                </div>

                <button v-if="noteEditingMode" type="button" class="btn-secondary btn-sm" @click="noteEditingMode = false">
                    Cancel
                </button>
                <button v-else type="button" class="btn-secondary p-2" @click="noteEditingMode = true">
                    <template v-if="invoice.notes">
                        Edit notes
                    </template>
                    <template v-else>
                        Add notes
                    </template>
                </button>
            </div>
            <div class="col-4">
                <!-- TODO formatting -->
                Subtotal: {{ formatMoney(Math.max(0, (invoice.amount ?? 0) + invoiceAmountDelta)) }}<br>
                <strong>Total amount due: {{ formatMoney(Math.max(0, (invoice.amount_due ?? 0) + invoiceAmountDelta)) }}</strong>
            </div>
        </div>
        <hr>
        <div class="text-right">
            <template v-if="invoice.status === 'draft'">
                <button type="button" class="btn btn-primary" @click="sendInvoice" :disabled="invoiceSendingInProgress">
                    Send Invoice
                </button>
            </template>
            <template v-if="invoice.status === 'unpaid'">
                <button type="button" class="btn btn-primary" @click="sendInvoice" :disabled="invoiceSendingInProgress">
                    Re-Send Invoice
                </button>
            </template>
            <template v-if="invoice.status === 'paid'">
                <button type="button" class="btn btn-primary" @click="sendInvoice">
                    Send Receipt
                </button>
            </template>

            <button type="button" class="btn btn-primary ml-3" @click="downloadInvoice">
                Download
            </button>

            <button type="button" class="btn btn-primary ml-3" @click="saveInvoice" :disabled="invoiceSavingInProgress">
                Save Invoice
            </button>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import Modal from '../Modal.vue';
import PlusCircleIcon from "@/Icons/plus-circle.vue";
import 'vue2-datepicker/index.css';

export default {
    name: 'InvoiceForm',
    components: {
        PlusCircleIcon,
        DatePicker,
        Modal,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        invoice: {
            type: Object,
            required: true,
        },
        subject: {
            type: Object,
            required: true,
        },
        linkToSubject: {
            type: String,
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            productsCanBeEdited: this.invoice.status === 'draft',

            addProductModal: false,
            noteEditingMode: false,
            invoiceDateModified: false,
            paymentDueDateModified: false,

            productToAdd: null,
            invoiceItemDescription: null,
            invoiceItemPrice: null,

            invoiceDate: null,
            paymentDueDate: null,
            invoiceNotes: this.invoice.notes,
            invoiceItemsToAdd: [],
            invoiceItemsToDelete: [],

            invoiceAmountDelta: 0,

            invoiceSendingInProgress: false,
            invoiceSavingInProgress: false,
        }
    },

    computed: {
        hasUnsavedChanges() {
            return this.invoiceDateModified
                || this.paymentDueDateModified
                || (this.noteEditingMode && this.invoiceNotes !== this.invoice.notes)
                || this.invoiceItemsToAdd.length > 0
                || this.invoiceItemsToDelete.length > 0;
        },
    },

    mounted: function () {
        // convert dates to Y-m-d format
        this.invoiceDate = new Date(this.invoice.invoiced_at).toISOString().slice(0, 10);

        if (this.invoice.payment_due_at) {
            this.paymentDueDate = new Date(this.invoice.payment_due_at).toISOString().slice(0, 10);
        }
    },

    watch: {
        invoiceDate: function (value, oldValue) {
            if (oldValue === null) {
                return;
            }

            this.invoiceDateModified = true;
        },
        paymentDueDate: function (value, oldValue) {
            if (oldValue === null && value !== null && this.invoice.payment_due_at !== null) {
                return;
            }

            this.paymentDueDateModified = true;
        },
    },

    created() {
        window.addEventListener('beforeunload', this.preventLosingChanges);
    },

    beforeDestroy() {
        window.removeEventListener('beforeunload', this.preventLosingChanges);
    },

    methods: {
        addInvoiceItem() {
            // Initialise array if needed
            if (this.invoice.invoice_items === undefined) {
                this.invoice.invoice_items = [];
            }

            const temporaryId = this.invoice.invoice_items.length + 1;

            this.invoice.invoice_items.push({
                temporary_id: temporaryId,
                invoiceable: {
                    id: this.productToAdd.id,
                    code: this.productToAdd.code,
                },
                name: this.productToAdd.name,
                description: this.invoiceItemDescription,
                total: this.invoiceItemPrice,
            });

            this.invoiceItemsToAdd.push({
                temporary_id: temporaryId,
                product_id: this.productToAdd.id,
                description: this.invoiceItemDescription,
                price: this.invoiceItemPrice,
            });

            this.invoiceAmountDelta += parseFloat(this.invoiceItemPrice);

            this.productToAdd = null;
            this.invoiceItemDescription = null;
            this.invoiceItemPrice = null;
            this.addProductModal = false;
        },

        removeInvoiceItem(item, key) {
            const deletedItems = this.invoice.invoice_items.splice(key, 1);

            console.log(deletedItems[0].total);
            this.invoiceAmountDelta -= parseFloat(deletedItems[0].total);

            this.$forceUpdate();

            // If the item was an existing item, add it to the list of items to delete.
            if (item.id) {
                this.invoiceItemsToDelete.push({
                    id: item.id,
                });
            }

            // If the item was added in this session, remove it from the list of items to add.
            if (item.temporary_id) {
                this.invoiceItemsToAdd = this.invoiceItemsToAdd.filter(function (obj) {
                    return obj.temporary_id !== item.temporary_id;
                });
            }
        },

        getInvoicePayload() {
            return {
                invoice_date: this.invoiceDateModified ? this.invoiceDate : null,
                payment_due_date: this.paymentDueDateModified ? this.paymentDueDate : null,
                notes: this.noteEditingMode ? this.invoiceNotes : null,
                invoice_items_to_add: this.invoiceItemsToAdd,
                invoice_items_to_delete: this.invoiceItemsToDelete,
            }
        },

        saveInvoice() {
            this.invoiceSavingInProgress = true;

            axios
                .post('/api/invoices/' + this.invoice.id, this.getInvoicePayload())
                .then(() => {
                    this.flash('Invoice successfully updated', 'success');

                    this.resetFormStateAfterSaving();

                    this.invoiceSavingInProgress = false;
                })
                .catch(() => {
                    this.flash('Failed to update invoice', 'error');

                    this.invoiceSavingInProgress = false;
                });
        },

        downloadInvoice() {
            window.location = '/invoice/download/' + this.invoice.id;
        },

        sendInvoice() {
            this.invoiceSendingInProgress = true;

            axios
                .post('/api/invoices/' + this.invoice.id + '/send', this.getInvoicePayload())
                .then((response) => {
                    this.flash('Invoice successfully sent', 'success');

                    this.invoice.status = response.data.invoice_status;

                    this.resetFormStateAfterSaving();

                    this.invoiceSendingInProgress = false;
                })
                .catch((error) => {
                    let errorMessage;
                    if (error.response.status === 422) {
                        errorMessage = error.response.data.message;
                    } else {
                        errorMessage = 'Failed to send invoice';
                    }

                    this.flash(errorMessage, 'error');

                    this.invoiceSendingInProgress = false;
                });
        },

        resetFormStateAfterSaving() {
            // Update invoice state
            this.invoice.notes = this.invoiceNotes;

            // Reset form state
            this.noteEditingMode = false;
            this.invoiceDateModified = false;
            this.paymentDueDateModified = false;
            this.invoiceItemsToAdd = [];
            this.invoiceItemsToDelete = []
        },

        // TODO maybe move this to a global mixin?
        flash(message, type) {
            this.$root.$refs.flash_alert.activateFlashAlert(message, type);
            window.scrollTo(0, 0);
        },

        preventLosingChanges(event) {
            if (this.hasUnsavedChanges && ! confirm('You have unsaved changes. Are you sure you want to leave this page?')) {
                event.preventDefault();

                event.returnValue = '';
            }
        },
    },
}
</script>

<style scoped>

</style>
