<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="Layer_1"><path d="m35.6 14-2-8H14.4l-2 8H6v4h36v-4h-6.4zm-18-4h12.9l1 4H16.6l1-4zM10 34.6l4.9 7.4H38c0-2.2-1.8-4-4-4h-4V24h4v10.1h4V20H14c-2.2 0-4 1.8-4 4v10.6zM26 38h-4V24h4v14zM14 24h4v14h-.9L14 33.4V24z" /></g></svg>
</template>

<script>
export default {
    name: 'TrashIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

