<template>
  <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g id="_1.-Foundations_Icons_ATAS_Icon_Help" data-name="1.-Foundations/Icons/ATAS_Icon_Help">
      <path id="Combined-Shape" class="cls-1" d="M24,3.78A4,4,0,0,1,27.6,6h0L43.4,38.46a4,4,0,0,1-3.6,5.76H8.2a4,4,0,0,1-3.6-5.76h0L20.4,6A4,4,0,0,1,24,3.78Zm0,4L8.2,40.22H39.8Zm0,24.44a3,3,0,1,1-3,3A3,3,0,0,1,24,32.22Zm2-14v12H22v-12Z" />
    </g>
  </svg>
</template>

<script>
export default {
    name: 'attention',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
