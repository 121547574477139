<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <g id="_1.-Foundations_Icons_ATAS_Icon_Sneak-Peek" data-name="1.-Foundations/Icons/ATAS_Icon_Sneak-Peek">
            <path id="Combined-Shape" class="cls-1" d="M24,8a22.43,22.43,0,0,1,10,2.24h0l.63.33h0l.61.35.36.2.82.54a6.62,6.62,0,0,1,.64.44h0l.2.14a28.85,28.85,0,0,1,8.88,11.7h0l-.34.78a33.36,33.36,0,0,1-2.14,4,27.61,27.61,0,0,0-6.92-11.8h0l-.4-.36-.84-.72c-.4-.32-.84-.64-1.28-.94h0l-.64-.4c-.48-.28-1-.56-1.52-.82h0l-.4-.18c-.58-.26-1.2-.48-1.86-.7h0l-.46-.14a21.24,21.24,0,0,0-2.24-.48h-.46A22.56,22.56,0,0,0,24,12C14.3,12,9.08,18.34,6.3,24,9.08,29.66,14.36,36,24,36a17.85,17.85,0,0,0,11.6-4,4,4,0,0,1,5.48.2A24.86,24.86,0,0,1,38.26,35h0l-.55.44A22,22,0,0,1,24,40C14.46,40,6.74,34.62,2.26,24.84h0L1.88,24l.38-.84C6.74,13.38,14.46,8,24,8Zm0,8a8,8,0,1,1-8,8A8,8,0,0,1,24,16Zm0,4a4,4,0,1,0,4,4A4,4,0,0,0,24,20Z" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'EyeIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

