<template>
    <div class="instructions-wrapper">
        <div class="instructions-wrapper-label">
            <button class="btn btn-primary hide-instructions pull-right like-button" v-show="showInstructions" @click="showInstructions = false"><i class="fa fa-caret-up"></i>Hide Instructions</button>
            <button class="btn btn-primary show-instructions pull-right like-button" v-show="!showInstructions" @click="showInstructions = true"><i class="fa fa-caret-down"></i>Show Instructions</button>

            <slot name="header">
                <h2>Please provide the following information for your order.</h2>
            </slot>
            <slot name="small-text">
                <div class="label small-caps">Required fields are marked with an asterisk <em class="yellow no-style">*</em></div>
            </slot>
        </div>
        <div class="instructions-wrapper wysiwyg-content" v-show="showInstructions">
            <slot name="instruction-content">

            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showInstructions: true,
                orderinfo: [],
            }
        }
    }
</script>
