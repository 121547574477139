<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xml:space="preserve">
        <g>
	<path id="Combined-Shape_00000181795343593254956050000002305009615790774189_" class="st0" d="M23.4,4C31,3.8,38.1,7.9,41.7,14.7
		c4.4,8.4,2.4,18.8-5,24.8c-7.3,6-17.9,6.1-25.3,0.1l0,0l2.8-2.9c6.5,5,15.7,4.2,21.3-1.7s5.9-15.1,0.6-21.4S21.8,6.1,15,10.7
		S6,24.3,9.9,31.5c0.9,1.8,0.4,4.1-1.3,5.2c-4.8-5.9-5.9-14-2.7-21S15.8,4.2,23.4,4z" />
</g>
        <g>
</g>
</svg>
</template>


<script>
export default {
    name: 'circle',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>


