<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <g id="_1.-Foundations_Icons_ATAS_Icon_Button_Round_Arrow_Down-copy"
           data-name="1.-Foundations/Icons/ATAS_Icon_Button_Round_Arrow_Down-copy">
            <path id="Combined-Shape" class="cls-1"
                  d="M38,6a4,4,0,0,1,4,4h0V38a4,4,0,0,1-4,4H6V38H38V10H10V30a4,4,0,0,1-4,4H6V10a4,4,0,0,1,4-4H38ZM26,14V27.18l4.58-4.6,2.84,2.84-8,8A2,2,0,0,1,24,34a1.62,1.62,0,0,1-.78-.14,1.88,1.88,0,0,1-.64-.44h0l-8-8,2.84-2.84L22,27.18V14Z" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'down_box',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
