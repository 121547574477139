<template>
    <div class="mb-4 text-right pr-30">
        <a v-if="navigation['previous']" :href="'/order/' + navigation['previous']" class="mr-2 btn btn-primary">Previous</a>
        <a v-else class="mr-2 btn btn-disabled" disabled="disabled">Previous</a>

        <a :href="navigation['list']" class="mr-2 btn btn-primary">Return to List</a>
        <a v-if="navigation['next']" :href="'/order/' + navigation['next']" class="mr-2 btn btn-primary">Next</a>
        <a v-else class="mr-2 btn btn-disabled" disabled="disabled">Next</a>
    </div>
</template>

<script>
    export default {
        props: {
            navigation: Object
        }
    }
</script>
