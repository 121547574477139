<template>
    <div class="">
        <div v-if="errors">
            {{ errors }}
        </div>

        <modal v-if="assignCheckModal" @close="assignCheckModal = false">
            <h2 slot="header">Assign Check to Invoice {{ assigned_invoice.invoice_number }}</h2>
            <div class="content" slot="body">
                <form id="finance-form-data">
                    <div class="invoice-wrapper-grey">
                        <div class="invoice-wrap-half">
                            <label>Check #</label>
                            <input type="text" name="check" v-model="check_assign_number"/>
                        </div>
                        <div class="invoice-wrap-half last">
                            <label>Check Amount</label>
                            <input type="text" name="amount" v-model="check_assign_amount"/>
                        </div>
                        <div class="invoice-wrap-full">
                            <label>Note</label>
                            <textarea name="note" v-model="check_assign_notes"></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left" @click="assignCheckModal = false">Cancel</button>
                <button class="btn btn-primary pull-right" @click.prevent="assignCheck">Save Check</button>
            </div>
        </modal>

        <modal v-if="refundModal" @close="refundModal = false">
            <h2 slot="header">Refund for Invoice {{ assigned_invoice.invoice_number }} </h2>
            <div class="content" slot="body">
                <form id="finance-refund-form-data" class="finance-form-data">
                    <div class="invoice-wrapper-grey">
                        <div class="alert alert-warning" role="alert" v-show="refund_amount >assigned_invoice.amount ">
                            Amount specified is more than the invoice amount
                        </div>
                        <div class="invoice-wrap-full">
                            <label>Refund Amount</label>
                            <input type="text" name="refund_amount" v-model="refund_amount"/>
                            <p class="pt-1">Maximum amount : ${{ assigned_invoice.amount_paid }}</p>
                        </div>
                        <div class="invoice-wrap-full">
                            <label style="width:100%">Type of refund</label>
                        </div>
                        <div class="d-flex align-items-center justify-content-start mt-2">
                            <div class="custom-control custom-radio mr-5">
                                <input type="radio" v-model="refund_type" value="check" class="custom-control-input"
                                       id="refund_type_check" name="refund_type">
                                <label class="custom-control-label no-border pl-1" for="refund_type_check">Check</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" v-model="refund_type" value="credit_card"
                                       class="custom-control-input" id="refund_type_credit_card" name="refund_type">
                                <label class="custom-control-label no-border pl-1" for="refund_type_credit_card">Credit
                                    Card </label>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="btn btn-black pull-left" @click="refundModal = false">Cancel</button>
                <button class="btn pull-right"
                        :disabled="refund_amount === 0 || refund_type == null || refund_amount>assigned_invoice.amount_paid"
                        @click.prevent="processRefund(assigned_invoice)">Process Refund
                </button>
            </div>
        </modal>

        <div class="search-filters invoice-filter-fields">

            <div class="choices-filter-group">
                <input class="form-control" placeholder="Invoice Number" type="text" v-model="query_invoice_num">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Order Number" type="text" v-model="query_order_num">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Submitter Name" type="text" v-model="query_submitter_name">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Billing Name" type="text" v-model="query_billing_name">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Invoice Total" type="text" v-model="query_dollars_total">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Payment Total" type="text" v-model="query_payment_total">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Balance" type="text" v-model="query_dollars_owing">
            </div>

            <div class="choices-filter-group double">
                <div class="pl-2">
                    <h4>Date</h4>
                    <label class="date" for="date_from">
                        <calendar-icon class="svg-accent svg-16"></calendar-icon>
                    </label>
                    <flat-pickr id="date_from" v-model="query_date_from" :config="config"
                                placeholder="Select date"></flat-pickr>
                </div>
                <div class="pl-2">
                    <span>To</span>
                    <label class="date" for="date_to">
                        <calendar-icon class="svg-accent svg-16"></calendar-icon>
                    </label>
                    <flat-pickr id="date_to" v-model="query_date_to" :config="config"
                                placeholder="Select date"></flat-pickr>
                </div>
            </div>

            <div class="choices-filter-group payment-input-wrapper">
                <v-select multiple id="payment_status" :placeholder="`Payment Status`" v-model="query_payment_status"
                          :options="payment_status_options"></v-select>
            </div>
            <div class="choices-filter-group payment-input-wrapper">
                <v-select id="reconciled" :placeholder="`Reconciled Status`" v-model="query_reconciled_status"
                          :options="reconciled_status_options"></v-select>
            </div>
            <div class="pull-right filter-buttons">
                <button class="clear-button no-border" @click="clearFilters">
                    <close-icon class="svg-16"></close-icon>
                    Clear Filters
                </button>
            </div>

        </div>


        <h3>Invoices</h3>

        <div class="entries-table-wrap">
            <table id="invoice-table" class="nice-table ">
                <thead>
                <tr class="">
                    <th class="th-balance ">
                        Submitter
                    </th>
                    <th class="th-balance">
                        Billing Contact
                    </th>
                    <th class="th-invoice" style="width:150px">
                        Invoice #
                    </th>
                    <th class="th-balance " v-if="invoiceType==='events'">
                        Event Info
                    </th>
                    <th class="th-balance ">
                        Invoice Total
                    </th>
                    <th class="th-balance ">
                        Outstanding Balance
                    </th>
                    <th class="th-balance">
                        Payment Status
                    </th>
                    <th class="th-actions">
                        Actions
                    </th>
                    <th class="th-balance">
                        Reconcile
                    </th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="(invoice, i) in invoices.data">

                    <td class="email">
                        <div class="td-line-break">{{ invoice.user.first_name }} {{ invoice.user.last_name }}<br/>
                            <span>{{ invoice.user.email }}</span>
                        </div>
                    </td>
                    <td class="">
                        <div v-if="invoice.contact">
                            {{ invoice.contact.name }}<br/>
                            <span>{{ invoice.contact.email }}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <a v-if="invoiceType==='events'"
                               :href="'/finance/invoice/'+invoice.id">{{ invoice.invoice_number }}</a>
                            <a v-if="invoiceType==='orders'"
                               :href="'/invoice/'+invoice.id+'/payment'">{{ invoice.invoice_number }}</a>
                        </div>
                    </td>
                    <td v-if="invoiceType==='events'">
                        <div>
                            <div>{{ invoice.subject.event_title }}</div>
                            <div>{{ invoice.subject.event_date }}</div>
                        </div>
                    </td>
                    <td>
                        <div>{{ formatPrice(invoice.amount) }}</div>
                    </td>
                    <td>
                        <div class="entry__category">{{ formatPrice(invoice.amount_due) }}</div>
                    </td>
                    <td class="pl-2">
                        {{ invoice.status }}
                    </td>

                    <td>
                        <div class="pull-right actions">
                            <template v-if="invoice.amount_due > 0">
                                <span v-if="invoice.status === 'payment-processing'" class="pr-2 small-caps-bold">Payment in process</span>
                                <button class="btn-finance" @click.prevent="openAssignModal(i)">
                                    <plus-circle-icon class="svg-16 svg-black"></plus-circle-icon>
                                    Record Check
                                </button>
                                <a :href="'/invoice/'+invoice.id+'/payment'" class="btn-finance">
                                    <plus-circle-icon class="svg-16 svg-black"></plus-circle-icon>
                                    Credit Card</a>
                            </template>
                            <a v-if="invoice.amount_due == 0" :href="'/invoice/download/'+invoice.id"
                               class="btn-finance">
                                <download class="svg-16 svg-black"></download>
                                Download Invoice</a>
                            <a v-if="invoice.amount_paid > 0" class="btn-finance"
                               @click.prevent="assigned_invoice = invoice; refundModal = true">
                                <refresh class="svg-16 svg-black"></refresh>
                                Refund</a>

                        </div>
                    </td>
                    <td class="pl-2">
                        <a v-if="invoice.is_reconciled != 1" class="btn-finance"
                           @click.prevent="reconcileInvoice(invoice)">
                            Reconcile</a>
                        <span v-if="invoice.is_reconciled == 1"><checkmark class="svg-16 svg-black"></checkmark> Reconciled</span>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>
        <div style="float:right;">
            <pagination :data="invoices" @pagination-change-page="clickPaginationCallback" :limit="3">
                <span slot="prev-nav">&lt; Previous</span>
                <span slot="next-nav">Next &gt;</span>
            </pagination>
        </div>
        <div class="dataTables_info" v-if="to">Showing {{ 1 + (num * (page - 1)) }}-{{ to }} of {{ total }} invoices
        </div>
        <div class="dataTables_info" v-else>Showing 0 invoices</div>
        <div class="dataTables_length admin-entry-table">
            <label>
                <span class="pr-2">Show</span>
                <select class="plain-select num-entries-select" v-model="num">
                    <option value="10">10 Invoices</option>
                    <option value="20">20 Invoices</option>
                    <option value="50">50 Invoices</option>
                    <option value="100">100 Invoices</option>
                </select>
                <span>per page</span>
            </label>
        </div>

    </div>
</template>

<script>
// register modal component
import modal from '../components/Modal.vue';
import CalendarIcon from "@/Icons/calendar.vue";
import Refresh from "@/Icons/refresh.vue";
import PlusCircleIcon from "@/Icons/plus-circle.vue";
import Download from "@/Icons/download.vue";
import CloseIcon from "@/Icons/close.vue";
import Checkmark from "@/Icons/checkmark.vue";

export default {
    name: "FinancialsTable",

    components: {
        Checkmark,
        CloseIcon,
        Download,
        PlusCircleIcon,
        Refresh,
        CalendarIcon,
        modal,
    },

    props: {
        invoiceType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            errors: false,
            num: 20,
            page: 1,
            total: 0,
            to: 0,
            invoices: {},
            assigned_invoice: [],
            assignCheckModal: false,
            refundModal: false,
            refund_amount: 0,
            refund_type: null,
            query_invoice_num: '',
            query_order_num: '',
            query_submitter_name: '',
            query_billing_name: '',
            query_dollars_total: '',
            query_payment_total: '',
            query_dollars_owing: '',
            query_date_from: '',
            query_date_to: '',
            query_payment_status: '',
            query_reconciled_status: '',
            payment_status_options: [
                'Paid',
                'Unpaid',
                'Draft',
                'Void',
            ],
            reconciled_status_options: [
                'Reconciled',
                'Unreconciled',
            ],
            check_assign_number: '',
            check_assign_amount: 0,
            check_assign_notes: '',

            config: {
                dateFormat: 'm-d-Y',
                minDate: "1-1-2018",
                maxDate: "today"
            },
        }
    },
    mounted() {
        let app = this;
        app.query_date_from = '1-1-2024';
        app.fetchInvoices();
    },
    methods: {
        processRefund(invoice) {
            console.log('Refunding');
            console.log(invoice);
            console.log(this.refund_type);
            console.log(this.refund_amount);

            // post to finance/refund
            axios.post('/finance/refund', {
                'refund_type': this.refund_type,
                'refund_amount': this.refund_amount,
                'invoice_id': invoice.id
            }).then(res => {
                this.errors = "Refund Complete"; //res.data;
                this.refund_amount = 0;
                this.refund_type = null;
                this.refundModal = false;
                // reload page
                window.location.reload();
            }).catch(err => {
                this.errors = 'Error processing refund : ';
                this.errors += err;
                console.log(err)
                alert('error processing your refund');
            });

        },
        clearFilters() {
            this.query_invoice_num = '';
            this.query_order_num = '';
            this.query_submitter_name = '';
            this.query_billing_name = '';
            this.query_dollars_total = '';
            this.query_payment_total = '';
            this.query_dollars_owing = '';
            this.query_date_from = '';
            this.query_date_to = '';
            this.query_payment_status = '';
            this.query_reconciled_status = '';
        },
        fetchInvoices() {
            let app = this;
            axios.get('/api/finance', {
                params: {
                    invoice_type: app.invoiceType,
                    num: app.num,
                    page: app.page,
                    invoice_num: app.query_invoice_num,
                    order_num: app.query_order_num,
                    submitter_name: app.query_submitter_name,
                    billing_name: app.query_billing_name,
                    dollars_total: app.query_dollars_total,
                    payment_total: app.query_payment_total,
                    dollars_owing: app.query_dollars_owing,
                    date_from: app.query_date_from,
                    date_to: app.query_date_to,
                    payment_status: app.query_payment_status,
                    reconciled_status: app.query_reconciled_status,
                }
            })
                .then(res => {
                    this.invoices = res.data;
                    this.total = res.data.total;
                    this.to = res.data.to;
                }).catch(err => {
                this.errors = 'Error contacting the server : ';
                this.errors += err;
                console.log(err)
            });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(',', '.')
            return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        openAssignModal(i) {
            this.assigned_invoice = this.invoices.data[i];
            this.check_assign_amount = this.assigned_invoice.amount_due.toFixed(2);
            this.assignCheckModal = true;
        },
        reconcileInvoice(invoice) {
            console.log('reconciling');
            console.log(invoice);
            let val = confirm('Are you sure you want to reconcile this invoice?');
            if (val) {
                // post to finance/reconcile
                axios.post('/api/finance/reconcile', {
                    'invoice_id': invoice.id
                }).then(res => {
                    this.errors = "Invoice Reconciled"; //res.data;
                    // reload page
                    window.location.reload();
                }).catch(err => {
                    this.errors = 'Error processing refund : ';
                    this.errors += err;
                    console.log(err)
                    alert('error processing your refund');
                });
            }
            // window.location.reload();
        },
        assignCheck() {
            let app = this;
            if (!(app.check_assign_amount > 0)) {
                alert("Check amount needs to be a value");
                return;
            }
            app.assignCheckModal = false;
            axios.post('/api/finance/apply_check', {
                check_assign_amount: app.check_assign_amount,
                check_assign_number: app.check_assign_number,
                check_assign_notes: app.check_assign_notes,
                invoice_id: app.assigned_invoice.id,
            })
                .then(res => {
                    this.errors = res.data;
                    window.location.reload();
                }).catch(err => {
                this.errors = 'Error contacting the server : ';
                this.errors += err;
                console.log(err);
                window.location.reload();
            });
        },
        clickPaginationCallback(pageNum) {
            let app = this;
            this.page = pageNum;
            this.fetchInvoices();
        }
    },
    watch: {
        query_invoice_num() {
            this.fetchInvoices();
        },
        query_order_num() {
            this.fetchInvoices();
        },
        query_submitter_name() {
            this.fetchInvoices();
        },
        query_billing_name() {
            this.fetchInvoices();
        },
        query_dollars_total() {
            this.fetchInvoices();
        },
        query_payment_total() {
            this.fetchInvoices();
        },
        query_dollars_owing() {
            this.fetchInvoices();
        },
        query_date_from() {
            this.fetchInvoices();
        },
        query_date_to() {
            this.fetchInvoices();
        },
        query_payment_status() {
            this.fetchInvoices();
        },
        query_reconciled_status() {
            this.fetchInvoices();
        },
        num() {
            this.page = 1;  // Reset to first page when number of items changed
            this.fetchInvoices();
        },
    }
}
</script>

<style>
.modal-container {
    width: 50% !important;
}
</style>
