<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="m10 14 8 10h-6a12.88 12.88 0 0 0 7.94 11.64A8.78 8.78 0 0 0 29 35a4 4 0 0 1 5.62 1 15.2 15.2 0 0 1-3 2.18h-.14l-.48.22a16 16 0 0 1-21.18-6.95A15.57 15.57 0 0 1 8 24H2zm7.13-4.46a16 16 0 0 1 21.05 7A15.57 15.57 0 0 1 40 24h6l-6.44 8a2 2 0 0 1-3.12 0L30 24h6a12 12 0 0 0-17.58-10.61 4 4 0 0 1-5.1-1.26 14.93 14.93 0 0 1 3.1-2.13l.14-.18z" style="fill-rule:evenodd" data-name="1.-Foundations/Icons/ATAS_Icon_Refresh" /></svg>

</template>

<script>
export default {
    name: 'refresh',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
