<template>
    <div>
        <div v-if="editor" class="wysiwyg-menu" :class="className">
            <editor-content v-show="!showTextarea" :editor="editor" class="wysiwyg-textarea" :class="textareaClass" />
            <textarea :name="machine_name" v-show="showTextarea" v-model="textarea_v_model" @blur="saveTextareaIntoEditor()" class="form-control" rows="16"></textarea>
            <div v-if="!showTextarea && showButtons">
                <button @click.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                    <i class="fas fa-fw fa-heading"></i>
                </button>
                <button @click.prevent="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                    <i class="fas fa-fw fa-list-ul"></i>
                </button>
                <button @click.prevent="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
                    <i class="fas fa-fw fa-list-ol"></i>
                </button>
                <button @click.prevent="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                    <i class="fas fa-fw fa-bold"></i>
                </button>
                <button @click.prevent="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                    <i class="fas fa-fw fa-italic"></i>
                </button>
                <button @click.prevent="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
                    <i class="fas fa-fw fa-strikethrough"></i>
                </button>
                <button @click.prevent="editor.chain().focus().toggleHighlight().run()" :class="{ 'is-active': editor.isActive('highlight') }">
                    <i class="fas fa-fw fa-highlighter"></i>
                </button>
                <button v-if="includeLinkOption" @click.prevent="setLink" :class="{ 'is-active': editor.isActive('link') }">
                    <i class="fas fa-fw fa-link"></i>
                </button>
                <button v-if="includeLinkOption" @click.prevent="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
                    <i class="fas fa-fw fa-unlink"></i>
                </button>
                <button @click.prevent="editor.chain().focus().clearNodes().unsetAllMarks().run()" :class="{ 'is-active': editor.isActive('clear') }">
                    <i class="fas fa-fw fa-remove-format"></i>
                </button>
                <button v-if="includeHtmlEditingOption" @click.prevent="changeToTextarea()" :class="{ 'is-active': editor.isActive('clear') }">
                    <i class="fas fa-fw fa-code"></i>
                </button>
            </div>
            <div v-if="showTextarea && includeHtmlEditingOption">
                <button @click.prevent="changeToTextarea()" class="is-active">
                    <i class="fas fa-fw fa-code"></i>
                </button>
            </div>
        </div>
        <bubble-menu :editor="editor" :tippy-options="{ duration: 100 }" :should-show="() => editor.isActive('link')" v-if="editor">
            <button @click.prevent="goToLink">
                go to link
            </button>
            <button @click.prevent="setLink">
                edit link
            </button>
            <button @click.prevent="editor.chain().focus().unsetLink().run()">
                remove link
            </button>
        </bubble-menu>
    </div>
</template>

<script>
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-2'

export default {
    components: {
        EditorContent,
        BubbleMenu,
    },

    props: {
        modelValue: {
            type: String,
            default: '',
        },
        className: String,
        textareaClass: String,
        showButtons: {
            type: Boolean,
            default: true,
        },
        includeHtmlEditingOption: {
            type: Boolean,
            default: false,
        },
        includeLinkOption: {
            type: Boolean,
            default: false,
        },
        machine_name: {
            type: String,
            required: true
        }
    },

    emits: ['update-model-value'],

    data() {
        return {
            editor: null,
            showTextarea: false,
            textarea_v_model: this.modelValue
        }
    },

    watch: {
        modelValue(value) {
            const isSame = this.editor.getHTML() === value

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },
    mounted() {
        this.editor = new Editor({
            extensions: [
                Highlight,
                Link.configure({
                    openOnClick: false,
                }),
                StarterKit,
            ],
            content: this.modelValue === 'null' ? '' : this.modelValue,
            onBlur: () => {
                this.textarea_v_model = this.removeNestedParagraphsFromListItems(this.editor.getHTML());
                this.$emit('update-model-value', this.editor.getHTML(), this.machine_name)
            }
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },

    methods: {
        goToLink() {
            window.open(this.editor.getAttributes('link').href, '_blank')
        },

        setLink(event, prefillUrl) {
            if (!prefillUrl) {
                prefillUrl = this.editor.getAttributes('link').href
            }

            const url = window.prompt('Please provide a full web address including https:// or http://', prefillUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }

            // Not a valid url, re-prompt
            if (!url.includes('https://') && !url.includes('http://')) {
                alert('The link must include a full address starting with https:// or http://')
                this.setLink(event, url)
                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
        },

        changeToTextarea() {
            this.showTextarea = !this.showTextarea;
        },

        saveTextareaIntoEditor() {
            this.$emit('update:modelValue', this.textarea_v_model)
            this.editor.commands.setContent(this.textarea_v_model, false)
        },

        removeNestedParagraphsFromListItems(content) {
            // Remove paragraph tags from within list elements
            let currentState;
            do {
                currentState = content;
                content = content.replace(/<li(.*?)><p>(.*?)<\/p>(.*?)<\/li>/gm, '<li$1>$2$3</li>')
            } while (currentState !== content);

            return content;
        },
    },
}
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 0;
    outline: 0;

    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    li > p {
        margin-bottom: 0.25rem;
    }

    h1 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.5rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    mark {
        background-color: #FAF594;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0D0D0D, 0.1);
        margin: 2rem 0;
    }
}

.wysiwyg-menu, .tippy-content {
    button {
        border: 1px solid #dee2e6;
        background-color: white;
        margin-right: 0.2rem;

        &:hover {
            background-color: #f1ad14;
        }

        &.is-active {
            color: white;
            background-color: black;
        }
    }
}
</style>
