<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <g id="_1.-Foundations_Icons_ATAS_Icon_Normalscreen" data-name="1.-Foundations/Icons/ATAS_Icon_Normalscreen">
            <path id="Combined-Shape" class="cls-1"
                  d="M18,26a4,4,0,0,1,4,4h0v8a4,4,0,0,1-4,4h0V30H6V26Zm24,0v4H30V42a4,4,0,0,1-4-4h0V30a4,4,0,0,1,4-4H42ZM18,6a4,4,0,0,1,4,4h0v8a4,4,0,0,1-4,4H6V18H18ZM30,6V18H42v4H30a4,4,0,0,1-4-4h0V10a4,4,0,0,1,4-4Z" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'collapse',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
