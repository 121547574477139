<template>

    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 48 48">
        <g id="Layer_1">
	<path d="M27.1,27.1h-6.2v-6.2h6.2V27.1z M16.2,20.9H10v6.2h6.2V20.9z M38,20.9h-6.2v6.2H38V20.9z M27.1,10h-6.2v6.2h6.2V10z
		 M16.2,10h-3.1c-1.7,0-3.1,1.4-3.1,3.1v3.1h6.2V10z M34.9,10h-3.1v6.2H38v-3.1C38,11.4,36.6,10,34.9,10z M27.1,31.8h-6.2V38h6.2
		V31.8z M16.2,31.8H10v3.1c0,1.7,1.4,3.1,3.1,3.1h3.1V31.8z M38,31.8h-6.2V38h3.1c1.7,0,3.1-1.4,3.1-3.1V31.8z" />
</g>
        <g id="Layer_2">
</g>
</svg>
</template>

<script>
    export default {
        name: 'phoneIcon',
        props: {
            svgClass: {
                type: String,
                default: 'svg-24'
            },
        }
    }
</script>
