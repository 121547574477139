<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path class="cls-1" d="m36,18h-2v-3.65c0-5.32-4.01-9.97-9.31-10.33-5.82-.39-10.69,4.24-10.69,9.98v4h-6v14c2.21,0,4-1.79,4-4v-6h20.35l-10.41,8.61-4.66-6.2-3.12,2.51,7.52,9.35,14.32-11.97v13.7H8v4h32v-20c0-2.21-1.79-4-4-4Zm-6,0h-12v-4c0-3.31,2.69-6,6-6s6,2.69,6,6v4Z"/>
    </svg>

</template>

<script>
export default {
    name: 'submitted',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
