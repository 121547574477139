<template>
    <div>
        <div class="accordion-header position-relative cursor-pointer d-flex justify-content-between mt-4">
            <a @click.prevent="accordionToggle()" class="w-100 header-button position-relative d-flex justify-content-between accordion-button py-3 py-lg-2">
                <h2 class="mb-0">{{ month }}</h2>
                <div class="accordion-icon">
                    <down-chevron-icon class="svg-accent" :class="(accordionStatus) ? 'rotate-0' : 'rotate-180'"></down-chevron-icon>
                </div>
            </a>
        </div>
        <div class="pl-30 pt-2 pb-3 border-radius-0 card-bg-white text-bg-light accordion-collapse collapse" :class="(accordionStatus) ? 'show' : ''">
            <div class="d-flex border-bottom pt-3 pb-3 mb-3" v-for="date in possibleMonthDates">
                <h3 class="mb-0 order-header mr-5 pr-5">{{ date }}</h3>
                <div>
                    <div class="" v-for="(email, index) in emailsArray[date]">
                        <div class="pb-1 d-flex align-items-center">
                            <div class="mr-2">
                                {{ index+1 + ')' }}
                            </div>
                            <div>
                                <a class="d-block" :href="'/event/' + email.emailable.order_id" v-if="email.emailable_type == 'App\\Models\\Event'">{{ email.emailable.order_id }} - {{ email.emailable.event_title ?? '' }} - {{ email.emailable.event_date }} - Order Status: {{ email.emailable.order_status}} - Asset Status: {{ email.emailable.asset_status }}</a>
                                <a class="mb-1 d-block" :href="'/order/' + email.emailable.order_id" v-else-if="email.emailable_type == 'App\\Models\\OrderVp'">{{ email.emailable.order_id }} - {{ (email.emailable.cable_network != null) ? email.emailable.cable_network == 'Other' ? email.emailable.cable_network_other : email.emailable.cable_network : '' }}  - {{ email.emailable.program_name ?? '' }} - Order Status: {{ email.emailable.order_status}} - Email Status: {{ email.email_status }}</a>
                                <a class="mb-1 d-block" :href="'/order/' + email.emailable.order_id" v-else-if="email.emailable_type == 'App\\Models\\OrderNetwork'">{{ email.emailable.order_id }} - {{ (email.emailable.cable_network != null) ? email.emailable.cable_network == 'Other' ? email.emailable.cable_network_other : email.emailable.cable_network : '' }} - Order Status: {{ email.emailable.order_status}} - Email Status: {{ email.email_status }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DownChevronIcon from "@/Icons/down_chevron.vue";
export default {
    components: {DownChevronIcon},
    props: {
        month: String,
        possibleMonthDates: Array,
        emailsArray: Object,
        currentMonth: Boolean,
    },
    data() {
        return {
            accordionStatus: this.currentMonth,
        }
    },
    methods: {
        accordionToggle() {
            this.accordionStatus = !this.accordionStatus;
        }
    }
}
</script>
