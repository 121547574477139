<template>
    <div>
        <div class="row justify-content-center">
            <div class="order-selection-item col-12 col-md-4" :class="activeClassVP" @click.prevent="orderSelected('showVP')">
                <div class="content-wrap" v-html="content_array.vp_button">
                </div>
            </div>

            <div class="order-selection-item col-12 col-md-4" :class="activeClassNetwork"  @click.prevent="orderSelected('showNetwork')">
                <div class="content-wrap">
                    <div v-html="content_array.network_button">
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrapper-orders">
            <div class="order-details-holder" v-show="showVP" v-html="content_array.vp_description">
            </div>
            <div class="order-details-holder" v-show="showNetwork" v-html="content_array.network_description">
            </div>
        </div>

        <div class="pull-right button-create-order">
            <a :href="ordervp_path" class="btn btn-primary btn-step-next btn-disabled" @click.prevent v-show="nothingSelected" aria-disabled="true">
                <div>
                    <span class="submit-text">Next Step</span>
                    <span>Order Details</span>
                </div>
                <right-small></right-small>
            </a>
            <a :href="ordervp_path" class="btn btn-primary btn-step-next" v-show="showVP">
                <div>
                    <span class="submit-text">Next Step</span>
                    <span>Order Details</span>
                </div>
                <right-small></right-small>
            </a>
            <a :href="ordernetwork_path" class="btn btn-primary btn-step-next" v-show="showNetwork">
                <div>
                    <span class="submit-text">Next Step</span>
                    <span>Order Details</span>
                </div>
                <right-small></right-small>
            </a>
        </div>
    </div>
</template>

<script>
    import RightSmall from "@/Icons/right-small.vue";
    export default {
        components: {RightSmall},
        props: ['ordervp_path', 'ordernetwork_path', 'orderemmy_path', 'content_array'],
        data() {
            return {
                showVP: false,
                showNetwork: false,
                activeClassVP: '',
                activeClassNetwork: '',
                activeClassEmmy: '',
                nothingSelected: true
            }
        },
        methods: {
            orderSelected: function(orderType) {
                var that = this;
                that.showVP = false;
                that.showNetwork = false;
                that.activeClassVP = that.activeClassNetwork = that.activeClassEmmy = '';
                switch (orderType) {
                    case 'showVP':
                        that.showVP = true;
                        that.activeClassVP = 'active';
                        break;
                    case 'showNetwork':
                        that.showNetwork = true;
                        that.activeClassNetwork = 'active';
                        break;
                }
                that.nothingSelected = false;
            }
        }
    }
</script>
