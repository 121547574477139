<template>
    <svg :class="svgClass" width="24px" height="24px" viewBox="0 0 24 24">
        <g id="1.-Foundations/Icons/ATAS_Icon_Stop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M19,2.99999996 L4.99999996,2.99999996 C3.89543047,2.99999996 2.99999996,3.89543047 2.99999996,4.99999996 L2.99999996,17 C4.10456947,17 4.99999996,16.1045695 4.99999996,15 L4.99999996,4.99999996 L19,4.99999996 L19,19 L2.99999996,19 L2.99999996,21 L19,21 C20.1045695,21 21,20.1045695 21,19 L21,4.99999996 C21,3.89543047 20.1045695,2.99999996 19,2.99999996 Z" fill="#000000"></path>
        </g>
    </svg>
</template>


<script>
export default {
    name: 'checkbox_empty',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
