<template>
    <div>
      <vue-dropzone :awss3="awss3" id="dropzone"
                    ref="myVueDropzone"
                    :options="dropzoneOptions"
                    v-on:vdropzone-s3-upload-error="s3UploadError"
                    v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                    @vdropzone-success="successUpload"
                    @vdropzone-error="errorUpload"></vue-dropzone>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    name: "VideoUploadTestComponent",
    components: {vueDropzone: vue2Dropzone},
    props: {
        csrf: String,
    },
    data() {
        return {
            awss3: {
              signingURL: '/api/signed_url',
              headers: { "X-CSRF-TOKEN": this.csrf, },
              params : { "filename" : "test" },
              sendFileToServer : false,
              withCredentials: false
            },
            dropzoneOptions: {
              url: '/logthis',
              thumbnailWidth: 150,
              maxFilesize: 500,
              headers: {
                "My-Awesome-Header": "header value",
                "X-CSRF-TOKEN": this.csrf,
              }
            }

        }
    },
    created: function() {

    },
    mounted () {
      console.log('mounting');
    },
    methods: {
      getSignedUrl(file) {
        console.log(file);
        let result = axios.get('/api/signed_url', {
          params: {
            filename: file.name,
            filetype: file.type
          }
        }).then((response) => {
          console.log(response.data);
          return response.data;
        })
        console.log(result);
      },
      s3UploadError(errorMessage){
        console.log(errorMessage);
      },
      s3UploadSuccess(s3ObjectLocation){
        console.log(s3ObjectLocation);
      },
      successUpload(file, response) {
        console.log('success');
        console.log(file);
        console.log(response);
      },
      errorUpload(file, error, xhr) {
        console.log('error');
        console.log(file);
        console.log(error);
        console.log(xhr);
      },
    }
}
</script>


<style scoped>

</style>
