<template>
    <div class="form-group-beside row">
        <label for="event_title" class="col-2 col-md-6 show-html-code" @click.prevent="showHtmlRenderedCode = !showHtmlRenderedCode">
            {{ wysiwygLabel }} :
            <i class="fas fa-fw fa-code"></i>
            <div class="description">Toggle HTML</div>
        </label>
        <div class="col-10 col-md-6 input-wrapper">
            <div class="wrapper-formatted" v-html="wysiwygValue" v-if="showHtmlRenderedCode"></div>
            <div class="wrapper-raw" v-else>
                {{ wysiwygValue }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['wysiwygValue', 'wysiwygLabel'],
    data() {
        return {
            showHtmlRenderedCode: true,
        }
    }
}
</script>
