<template>
    <svg :class="svgClass" width="24px" height="24px" viewBox="0 0 24 24">
        <g id="1.-Foundations/Icons/ATAS_Icon_Button_Close" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M5.70999997,4.28999997 L12,10.59 C12.7754472,11.3700749 12.7754472,12.6299251 12,13.41 L12,13.41 L5.70999997,19.71 L4.28999997,18.29 L10.59,12 L4.28999997,5.70999997 L5.70999997,4.28999997 Z M14.12,12.71 L15.54,14.12 L19.71,18.29 L18.29,19.71 L14.12,15.54 L14.000973,15.4101488 C13.7001579,15.0514736 13.5334267,14.5968387 13.5334267,14.125 C13.5334267,13.5941815 13.7444459,13.0851365 14.12,12.71 L14.12,12.71 Z M18.29,4.28999997 L19.71,5.70999997 L15.54,9.87999999 L14.12,11.29 C13.7444459,10.9148635 13.5334267,10.4058185 13.5334267,9.87499999 C13.5334267,9.34418149 13.7444459,8.8351365 14.12,8.45999999 L14.12,8.45999999 L18.29,4.28999997 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'CloseIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
