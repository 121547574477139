<template>
    <svg :class="svgClass" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="m31.21 28.38.28-.38.42-.62.38-.5.42-.74c.06-.12.11-.24.16-.36.16-.32.3-.62.44-.94a14.45 14.45 0 0 0 .94-3.78 7.69 7.69 0 0 0 0-1 3.23 3.23 0 0 0 0-.44v-2.24a.87.87 0 0 0-.1-.54A4 4 0 0 1 34 16c-.06-.28-.1-.38-.14-.58a7.36 7.36 0 0 0-.22-.74c-.08-.24-.16-.46-.26-.7s-.14-.38-.22-.56-.3-.66-.46-1a1.47 1.47 0 0 0-.14-.28 15 15 0 0 0-2.66-3.52 15 15 0 1 0-10.6 25.6h1a15.25 15.25 0 0 0 3.9-.78 4 4 0 0 0-4.2-3.16h-.68a11 11 0 1 1 9.14-17.1l.12.18a11 11 0 0 1 .9 1.74v.14a13.87 13.87 0 0 1 .58 2 11.26 11.26 0 0 1-.54 6v.2a9.77 9.77 0 0 1-.8 1.54c0 .14-.12.26-.2.38a11.07 11.07 0 0 1-1.4 1.72l-1.45 1.42 15.22 15.22 2.84-2.84z" style="fill-rule:evenodd" data-name="1.-Foundations/Icons/ATAS_Icon_Search" /></svg>

</template>

<script>
export default {
    name: 'SearchIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

