<template>
    <svg :class="svgClass" viewBox="0 0 90 90" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Combined Shape</title>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon/Plus" transform="translate(-30.000000, -30.000000)" fill="#f1ad14">
                <path d="M78.75,83.0435 L78.75,120.0005 L71.25,120.0005 L71.25,90.5435 C71.25,86.4015 74.608,83.0435 78.75,83.0435 L78.75,83.0435 Z M78.7504,30 L78.7504,70 C78.7504,70.425 78.7094,70.84 78.6504,71.25 L78.6504,71.25 L120.0004,71.25 L120.0004,78.75 L30.0004,78.75 L30.0004,71.25 L70.0004,71.25 C70.6894,71.25 71.2504,70.689 71.2504,70 L71.2504,70 L71.2504,30 L78.7504,30 Z" id="Combined-Shape"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'PlusIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
