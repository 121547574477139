<template>
    <svg :class="svgClass" width="24px" height="24px" viewBox="0 0 24 24">
        <g id="1.-Foundations/Icons/ATAS_Icon_Button_Square_Tick" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M19,2.99999996 C20.1045695,2.99999996 21,3.89543047 21,4.99999996 L21,4.99999996 L21,19 C21,20.1045695 20.1045695,21 19,21 L19,21 L2.99999996,21 L2.99999996,19 L19,19 L19,4.99999996 L4.99999996,4.99999996 L4.99999996,15 C4.99999996,16.1045695 4.10456947,17 2.99999996,17 L2.99999996,17 L2.99999996,4.99999996 C2.99999996,3.89543047 3.89543047,2.99999996 4.99999996,2.99999996 L4.99999996,2.99999996 Z M15.79,8.28999998 L17.2,9.70999999 L11.91,15 C11.5348635,15.3755541 11.0258185,15.5865733 10.495,15.5865733 C9.9641815,15.5865733 9.4551365,15.3755541 9.07999999,15 L9.07999999,15 L6.78999998,12.71 L8.20999998,11.3 L10.5,13.59 L15.79,8.28999998 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'checkbox_checked',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
