<template>

    <svg :class="svgClass" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <g id="_1.-Foundations_Icons_ATAS_Icon_Button_Round_Add">
            <path id="Combined-Shape" class="cls-1" d="M23.42,4a20.05,20.05,0,1,1-12,35.67h0L14.3,36.7a16,16,0,1,0-4.42-5.18A4,4,0,0,1,8.56,36.7,20,20,0,0,1,23.42,4ZM26,16.06v6h6v4H26v6H22v-6H16v-4h6v-6Z" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'PlusCircleIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
