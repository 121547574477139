<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <g
            id="_1.-Foundations_Icons_ATAS_Icon_Arrow_Down_Small"
            data-name="1.-Foundations/Icons/ATAS_Icon_Arrow_Down_Small"
        >
            <path
                id="Combined-Shape"
                class="cls-1"
                d="M11.44,15.31l14,14a2,2,0,0,1,0,2.8,2,2,0,0,1-2.84,0l-14-14Zm25.14,0,2.84,2.82L28.58,28.93a4,4,0,0,1,0-5.64Z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'DownChevronIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
