<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 48 48">
        <path fill-rule="evenodd" d="M23.4 4c7.6-.2 14.7 3.9 18.3 10.7 4.4 8.4 2.4 18.8-5 24.8-7.3 6-17.9 6.1-25.3.1l2.8-2.9c6.5 5 15.7 4.2 21.3-1.7s5.9-15.1.6-21.4S21.8 6.1 15 10.7 6 24.3 9.9 31.5c.9 1.8.4 4.1-1.3 5.2-4.8-5.9-5.9-14-2.7-21S15.8 4.2 23.4 4zm.6 12.1c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z" clip-rule="evenodd" />
    </svg>
</template>

<script>
export default {
    name: 'CircleFilledIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
