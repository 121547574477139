<template>
    <div class="question-wrap">
        <div class="actual-question-icon pull-right" id="questionPop">
            <span class="actual-question-icon d-flex align-items-center w-auto px-3 py-3" @mouseover="showPopupClass = 'showme'" @mouseleave="showPopupClass = '' ">
                <question></question>
                Help
            </span>
            <div class="question-content" id="questionPopContent" :class="showPopupClass" @mouseover="showPopupClass = 'showme'" @mouseleave="showPopupClass = ''">
                <div class="question-content-wrap">
                    <div class="contact--email">
                        <mail-icon class="mr-2 accent"></mail-icon>
                        <div class=""><label>Email</label><span><a
                            :href="'mailto:' + emailaddress" v-text="emailaddress"></a></span>
                        </div>
                    </div>
                    <div class="contact--phone">
                        <phone-icon class="mr-2 accent"></phone-icon>
                        <div class="" x-ms-format-detection="none"><label>Call</label><span>818.754.2881</span>
                        </div>
                    </div>
                    <div class="contact--download">
                        <a href="https://www.emmys.com/fyc/partner" target="_blank">
                            <link-icon class="mr-2 accent"></link-icon>
                            <div class="">
                                <label>Link</label>
                                <span>FYC Partner Center</span>
                            </div>
                        </a>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>

<script>
    import Question from "@/Icons/question.vue";
    import LinkIcon from "@/Icons/link.vue";
    import MailIcon from "@/Icons/mail.vue";
    import EmailIcon from "@/Icons/email.vue";
    import PhoneIcon from "@/Icons/phone.vue";
    export default {
        components: {PhoneIcon, EmailIcon, MailIcon, LinkIcon, Question},
        props: ['emailaddress', 'phone'],
        data() {
            return {
                showPopupClass: '',
            }
        },
    }
</script>
