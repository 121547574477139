<template>

    <svg :class="svgClass" viewBox="0 0 31 31" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Album/Carousel" transform="translate(-382.000000, -792.000000)" fill="#f1ad14">
                <g id="Icon/Play" transform="translate(375.000000, 785.000000)">
                    <path d="M17.9395571,29.971544 L24.4721716,26.1981311 L31.0044856,22.4250188 L24.4721716,18.6519065 L17.9395571,14.8784936 L17.9395571,22.4250188 L17.9395571,29.971544 Z M33.30893,12.639157 C38.4400674,18.3245287 38.2708925,27.1595216 32.9372261,32.6552005 C30.3461159,35.3250284 26.9866573,36.7869554 23.5520766,37.05 L23.5520766,34.791123 C26.7453652,34.5019242 29.8473054,32.982879 32.0784306,30.2297786 C35.7753376,25.6675325 35.7191463,19.0189665 31.9522256,14.5141392 C26.9791451,8.56692543 17.8707452,8.56692543 12.8976647,14.5144398 C9.13074403,19.0189665 9.07455273,25.6675325 12.7714597,30.2297786 C14.5284145,32.3978677 16.8256471,33.8008726 19.2821386,34.4402966 C20.4699686,34.7493364 21.2981142,35.8222579 21.2981142,37.05 C17.8635335,36.787256 14.5037744,35.3250284 11.9126642,32.6552005 C6.57929831,27.1595216 6.40982293,18.3245287 11.5409603,12.6394577 C17.3641221,6.18689755 27.4854678,6.18689755 33.30893,12.639157 L33.30893,12.639157 Z" id="Fill-1"></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'playIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
