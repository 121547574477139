<template>
    <div>
        <div class="row justify-content-center">
            <div class="order-selection-item col-12 col-md-4" :class="activeClass === 'Person'?'active':''"  @click.prevent="eventSelected('In-Person')">
                <div class="content-wrap">
                    <div>
                        <h3>In-Person Event</h3>
                    </div>
                </div>
            </div>

            <div class="order-selection-item col-12 col-md-4" :class="activeClass === 'Virtual'?'active':''"  @click.prevent="eventSelected('Virtual')">
                <div class="content-wrap">
                    <div>
                        <h3>Virtual Event</h3>
                    </div>
                </div>
            </div>

            <div class="order-selection-item col-12 col-md-4" :class="activeClass === 'Hybrid'?'active':''"  @click.prevent="eventSelected('Hybrid')">
                <div class="content-wrap">
                    <div>
                        <h3>Hybrid Event</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrapper-orders">
            <div class="order-details-holder wysiwyg-content" v-show="show === 'Person'" v-html="content_array.in_person_description">
            </div>
            <div class="order-details-holder wysiwyg-content" v-show="show === 'Virtual'" v-html="content_array.virtual_description">
            </div>
            <div class="order-details-holder wysiwyg-content" v-show="show === 'Hybrid'" v-html="content_array.hybrid_description">
            </div>
        </div>

        <div class="pull-right button-create-order">
          <form action="/event/new" method="POST">
              <input type="hidden" :value="csrf_token" name="_token">
              <input type="hidden" :value="orderTypeSelected" name="type">
            <button type="submit"  class="btn btn-primary btn-step-next" v-show="show !== ''">
                <div>
                    <span class="submit-text">Next Step</span>
                    <span>Create Order</span>
                </div>
                <right-small></right-small>
            </button>
          </form>
        </div>
    </div>
</template>

<script>
    import RightSmall from "@/Icons/right-small.vue";
    export default {
        components: {RightSmall},
        props: ['order_event_path','csrf_token', 'content_array'],
        data() {
            return {
                activeClass: '',
                show: '',
                orderTypeSelected: null,
                nothingSelected: true
            }
        },
        methods: {
            eventSelected: function(orderType) {
                this.orderTypeSelected = orderType;
                this.show = '';
                this.activeClass = '';
                switch (orderType) {
                    case 'In-Person':
                        this.show = 'Person';
                        this.activeClass = 'Person';
                        break;
                    case 'Hybrid':
                        this.show = 'Hybrid';
                        this.activeClass = 'Hybrid';
                        break;
                    case 'Virtual':
                        this.show = 'Virtual';
                        this.activeClass = 'Virtual';
                        break;
                }
                this.nothingSelected = false;
            }
        }
    }
</script>
