<template>
    <svg :class="svgClass" viewBox="0 0 83 89" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon/Calendar" transform="translate(-34.000000, -31.000000)" fill="#f1ad14">
                <path d="M41.5,62.25 L41.5,49.75 L107.73,49.75 C108.421,49.75 108.98,50.31 108.98,51 L108.98,62.25 L41.5,62.25 Z M107.73,42.25 L91.98,42.25 L91.98,34.75 C91.98,32.679 90.302,31 88.23,31 C86.159,31 84.48,32.679 84.48,34.75 L84.48,42.25 L61.98,42.25 L61.98,34.75 C61.98,32.679 60.302,31 58.23,31 C56.159,31 54.48,32.679 54.48,34.75 L54.48,42.25 L34,42.25 L34,106 C38.143,106 41.5,102.642 41.5,98.5 L41.5,69.75 L108.98,69.75 L108.98,112.25 L34,112.25 L34,119.75 L116.48,119.75 L116.48,51 C116.48,46.167 112.563,42.25 107.73,42.25 L107.73,42.25 Z" id="Fill-1"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'CalendarIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

