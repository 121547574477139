<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48" xml:space="preserve"><path d="M4 9.6v20.8c0 2.2 1.8 4 4 4h24.8c0-2-1.6-3.6-3.6-3.6H7.6V11.7l4.9 4.9c1 1 2.4 1.6 3.8 1.6h11.4c1.4 0 2.8-.6 3.8-1.6l4.9-4.9v22.7H40V9.6c0-2.2-1.8-4-4-4H8c-2.2 0-4 1.8-4 4zm25 4.5c-.3.3-.8.5-1.3.5H16.3c-.5 0-.9-.2-1.3-.5l-4.9-4.9h23.7L29 14.1z" style="fill-rule:evenodd;clip-rule:evenodd" transform="translate(2 4)" /></svg>

</template>

<script>
export default {
    name: 'mailIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
