<template>
    <svg :class="svgClass" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M38 6a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H6v-4h32V10H10v20a4 4 0 0 1-4 4V10a4 4 0 0 1 4-4h28zm-15.4 8.56a2 2 0 0 1 2.18-.42 1.88 1.88 0 0 1 .64.44l8 8-2.84 2.84-4.58-4.6V34h-4V20.82l-4.56 4.58-2.84-2.84z" style="fill-rule:evenodd" data-name="1.-Foundations/Icons/ATAS_Icon_Button_Round_Arrow_Up-copy" /></svg>
</template>

<script>
export default {
    name: 'up-arrow-box',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

