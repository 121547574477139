<template>

    <div class="col-12" v-if="loadingComplete">
        <div v-if="errors">
            {{ errors }}
        </div>
        <form>

            <div class="row">
                <div class="form-search-wrap col-12">
                    <div class="choices-filter-group double">
                        <input type="text" name="keyword" id="keyword" placeholder="Enter a keyword, event title, program name, or network_platform name" v-model="query_keyword" >
                    </div>
                </div>

                <div class="col-12 pt-2 pull-right ">
                    <a class="pull-right clear-button no-border cursor-pointer" v-on:click.stop="clearSearch()"><close-icon class="svg-16"></close-icon> Clear Search</a>
                </div>
            </div>

        </form>

        <div class="entries-table-wrap">
            <table id="sortable-table nice-table">
                <thead>

                <tr class="tableHeaderRow">
                    <th style="width: 145px;" class="cursor-pointer th-entry pl-2" v-on:click="setSort('order_id')">Event # <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'order_id'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 235px;" class="cursor-pointer th-event_title" v-on:click="setSort('event_title')">Event Title <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'event_title'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 190px;" class="cursor-pointer th-event_date" v-on:click="setSort('event_date')">Event Date <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'event_date'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 100px;" class="th-send_at" v-on:click="setSort('send_at')">Email Date <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'send_at'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 100px;" class="cursor-pointer th-program_title" v-on:click="setSort('program_title')">Program <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'program_title'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 100px;" class="cursor-pointer th-network_platform" v-on:click="setSort('network_platform')">Network <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'network_platform'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 100px;" class="cursor-pointer th-type" v-on:click="setSort('type')">Type <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'type'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 130px;" class="cursor-pointer th-status" v-on:click="setSort('order_status')">
                        Status <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'order_status'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 130px;" class="cursor-pointer th-status" v-on:click="setSort('asset_status')">
                        Asset Status <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'asset_status'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>                </tr>
                </thead>
                <tbody v-if="orders.data && orders.data.length > 0">
                <tr @click="loadOrder(order.order_id)" class="cursor-pointer" v-for="(order,i) in orders.data" >
                    <td  class="pl-2"><div>{{ order.order_id }}</div></td>
                    <td><div>{{ order.event_title }}</div></td>
                    <td><div>{{ order.event_date }} - {{ order.time_slot == 'Open' ? order.time_slot_other : order.time_slot }}</div></td>
                    <td><div v-if="order.send_at">{{ order.send_at }}</div></td>
                    <td><div>{{ order.program_title }}</div></td>
                    <td><div>{{ order.network_platform == 'Other' ? order.network_platform_other : order.network_platform }}</div></td>
                    <td><div>{{ order.type }}</div></td>
                    <td class="status">
                        <div class="">
                            {{ order_status_options.find(x => x.id === order.order_status).text }}
                        </div>
                    </td>
                    <td class="status">
                        <div class="" v-if="order.asset_status">
                            {{ asset_status_options.find(x => x.id === order.asset_status).text }}
                        </div>
                    </td>
                </tr>
                </tbody>

                <tbody v-else>
                <tr>
                    <td colspan="9" class="pl-2">No Event Orders found. <span v-show="query_keyword !== ''">Try expanding your search query.</span></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div style="float:right;">
            <pagination :data="orders" @pagination-change-page="paginationCalculator" :limit="3">
                <span slot="prev-nav">&lt; Previous</span>
                <span slot="next-nav">Next &gt;</span>
            </pagination>
        </div>
        <div class="dataTables_info" v-if="to">Showing 1-{{to}} of {{ total }} events</div>
        <div class="dataTables_info" v-else>Showing 0 events</div>
        <div class="dataTables_length admin-entry-table">
            <label>
                <span class="pr-2">Show</span>
                <select class="plain-select num-entries-select" v-model="num">
                    <option value="10">10 Events</option>
                    <option value="20">20 Events</option>
                    <option value="50">50 Events</option>
                    <option value="100">100 Events</option>
                </select>
                <span>per page</span>
            </label>
        </div>

    </div>
</template>

<script>
import CloseIcon from "@/Icons/close.vue";
import DownChevronIcon from "@/Icons/down_chevron.vue";
import UpChevronIcon from "@/Icons/up-chevron.vue";
    export default {
        name: "EventsTable",
        components: {UpChevronIcon, DownChevronIcon, CloseIcon},
        data() {
            return {
                loadingComplete: false,
                errors: false,
                num:20,
                page:1,
                total: 0,
                to: 0,
                sort:'',
                sort_order: 'desc',
                orders: {},
                query_keyword: (localStorage.getItem('fyc-history-event-search') != null) ? localStorage.getItem('fyc-history-event-search') : '',
                query_order_status: '',
                query_type: '',
                query_file_status: '',
                order_status_options: [
                    {text: "In Progress", id: "in_progress"},
                    {text: "Submitted", id: "submitted"},
                    {text: "Canceled", id: "canceled"},
                    {text: "Approved", id: "approved"},
                    {text: "Needs More Information", id: "need_more_info"},
                    {text: "Order Completed", id: "order_completed" },
                ],
                asset_status_options: [
                    { text: "In Progress", id: "in_progress" },
                    { text: "Assets Submitted", id: "submitted" },
                    { text: "Internal Review", id: "internal_review" },
                    { text: "Approved For Go Live", id: "approved" },
                    { text: "Need More Information", id: "need_more_info" },
                    { text: "Building Underway", id: "building" },
                    { text: "Build Complete", id: "build_complete" },
                    { text: "Published & Sent", id: "published" },
                    { text: "Canceled", id: "canceled" },
                ],
                file_status_options: [
                    'Uploading',
                    'Upload Failed',
                    'Upload Successful',
                    'Closed Caption File Submitted',
                    'Processing',
                    'Processing Failed',
                    'Under Review',
                    'Need More Info',
                    'Video Approved',
                    'Canceled',
                    'Deleted'],
            }
        },
        mounted() {
            if(localStorage.getItem('fyc-history-event-search-sort')) {
                this.sort = localStorage.getItem('fyc-history-event-search-sort');
            }
            if(localStorage.getItem('fyc-history-event-search-sort-order')) {
                this.sort_order = localStorage.getItem('fyc-history-event-search-sort-order');
            }
            if(localStorage.getItem('fyc-history-event-search-num')) {
                this.num = localStorage.getItem('fyc-history-event-search-num');
            }
            if(localStorage.getItem('fyc-history-event-search-page')) {
                this.page = localStorage.getItem('fyc-history-event-search-page');
            }
            if(localStorage.getItem('fyc-history-event-search-status')) {
                var searchStatus = localStorage.getItem('fyc-history-event-search-status');
                this.query_order_status = searchStatus.split(',');
            }
            if(localStorage.getItem('fyc-history-event-search-filestatus')) {
                var searchFileStatus = localStorage.getItem('fyc-history-event-search-filestatus');
                this.query_file_status = searchFileStatus.split(',');
            }
            if(localStorage.getItem('fyc-history-event-search-type')) {
                this.query_type = localStorage.getItem('fyc-history-event-search-type');
            }
            this.fetchOrders(this.page);
        },
        methods:{
            loadOrder(order_id){
                window.location = '/event/'+order_id;
            },
            fetchOrders(page = 1){
                let app = this;
                axios.get('/api/events', {
                    params: {
                        num: app.num,
                        page: page,
                        sort: app.sort,
                        sort_order: app.sort_order,
                        keyword: app.query_keyword,
                        order_status: app.query_order_status,
                        file_status: app.query_file_status,
                        type: app.query_type
                        }
                    })
                    .then(res => {
                        app.loadingComplete = true;
                        app.orders = res.data;
                        app.total = res.data.total;
                        app.to = res.data.to;
                        localStorage.setItem('fyc-history-event-search-to', app.to);
                        localStorage.setItem('fyc-history-event-search', app.query_keyword);
                        localStorage.setItem('fyc-history-event-search-status', app.query_order_status);
                        localStorage.setItem('fyc-history-event-search-filestatus', app.query_file_status);
                        localStorage.setItem('fyc-history-event-search-type', app.query_type);
                        localStorage.setItem('fyc-history-event-search-num', app.num);
                    }).catch(err => {
                    this.errors = 'Error contacting the server : ';
                    this.errors += err;
                    console.log(err)
                });
            },
            setSort(sort) {
                if (this.sort == sort) {
                    this.sort_order = (this.sort_order == 'desc')?'asc':'desc';
                } else {
                    this.sort = sort;
                    this.sort_order = 'desc';
                }
                localStorage.setItem('fyc-history-event-search-sort', this.sort);
                localStorage.setItem('fyc-history-event-search-sort-order', this.sort_order);
                this.fetchOrders();
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return '$'+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            openAssignModal(i){
                this.assigned_invoice = this.invoices[i];
                this.check_assign_amount = this.assigned_invoice.amount_due.toFixed(2);
                this.assignCheckModal = true;
            },
            assignCheck() {
                let app = this;
                if (!(app.check_assign_amount > 0)) {
                    alert("Check amount needs to be a value");
                    return;
                }
                app.assignCheckModal = false;
                axios.post('/api/finance/apply_check', {
                    check_assign_amount: app.check_assign_amount,
                    check_assign_number: app.check_assign_number,
                    check_assign_notes: app.check_assign_notes,
                    invoice_id: app.assigned_invoice.id,
                })
                    .then(res => {
                        this.errors = res.data;
                        window.location.reload();
                    }).catch(err => {
                    this.errors = 'Error contacting the server : ';
                    this.errors += err;
                    console.log(err);
                    window.location.reload();
                });
            },
            clearSearch: function() {
                this.query_keyword = '';
                this.sort = '';
                this.sort_order = 'desc';
                this.num = 20;
                this.query_order_status = '';
                this.query_file_status = '';
                this.query_type = '';
                localStorage.removeItem('fyc-history-event-search');
                localStorage.removeItem('fyc-history-event-search-status');
                localStorage.removeItem('fyc-history-event-search-filestatus');
                localStorage.removeItem('fyc-history-event-search-type');
                localStorage.removeItem('fyc-history-event-search-sort');
                localStorage.removeItem('fyc-history-event-search-sort-order');
                localStorage.removeItem('fyc-history-event-search-num');
                localStorage.removeItem('fyc-history-event-search-page');
            },
            paginationCalculator: function(pageIndex) {
                localStorage.setItem('fyc-history-event-search-page', pageIndex);
                this.fetchOrders(pageIndex);
            },
        },
        watch: {
            query_keyword() {
                // clear the pagination when the keyword is changing
                localStorage.removeItem('fyc-history-event-search-page');
                this.fetchOrders(this.page);
                },
            query_order_status() {
                localStorage.removeItem('fyc-history-event-search-page');
                this.fetchOrders(this.page);
                },
            query_file_status() {
                localStorage.removeItem('fyc-history-event-search-page');
                this.fetchOrders(this.page);
            },
            query_type() {
                localStorage.removeItem('fyc-history-event-search-page');
                this.fetchOrders(this.page);
            },
            num() {
                this.page = 1;  // Reset to first page when number of items changed
                this.fetchOrders(this.page);
                },
        }
    }
</script>
