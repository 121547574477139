<template>
    <svg :class="svgClass" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="1.-Foundations/Icons/ATAS_Icon_Button_Tick" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M3.87999997,13.39 L8.82999999,18.39 C8.45486347,18.7655541 7.94581848,18.9765733 7.41499998,18.9765733 C6.88418148,18.9765733 6.37513649,18.7655541 5.99999997,18.39 L5.99999997,18.39 L2.42999996,14.85 L3.87999997,13.39 Z M20.18,4.17999997 L21.59,5.58999997 L11.19,16 L9.77999999,17.4 C9.4044459,17.0248635 9.19342668,16.5158185 9.19342668,15.985 C9.19342668,15.4541815 9.4044459,14.9451365 9.77999999,14.57 L9.77999999,14.57 L20.18,4.17999997 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
</template>


<script>
export default {
    name: 'checkmark',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

