<template>
    <svg :class="svgClass" width="24px" height="24px" viewBox="0 0 24 24">
        <g id="1.-Foundations/Icons/ATAS_Icon_Button_Rounded_Tick" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M11.7065481,1.98001182 C15.5204498,1.87184606 19.0635362,3.94335633 20.84,7.31999998 C23.0624473,11.5165488 22.0270435,16.69736 18.3623465,19.7173418 C14.6976495,22.7373236 9.4144516,22.7635083 5.71999997,19.78 L5.71999997,19.78 L7.13999998,18.33 C10.3895137,20.8073774 14.9837344,20.4377235 17.7953462,17.4726641 C20.606958,14.5076047 20.7321872,9.90023827 18.0857893,6.78682907 C15.4393915,3.67341987 10.8720336,3.05475243 7.49274575,5.35195771 C4.11345793,7.64916298 3.00848963,12.1238197 4.92999997,15.73 C5.40191699,16.6376318 5.1128503,17.7550687 4.25999997,18.32 C1.83948502,15.3706521 1.30819491,11.3009657 2.89042775,7.82906635 C4.47266059,4.35716697 7.89264642,2.08817758 11.7065481,1.98001182 Z M15.79,8.28999998 L17.2,9.70999999 L11.91,15 C11.5350171,15.3719209 11.028145,15.5804215 10.5,15.5800335 C9.96839402,15.5830735 9.45743765,15.374373 9.07999999,15 L9.07999999,15 L6.78999998,12.71 L8.20999998,11.3 L10.5,13.59 L15.79,8.28999998 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
</template>


<script>
export default {
    name: 'circle_check',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

