<template>
    <svg :class="svgClass" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="m29.13 21.18-8.6-8.6-2.82 2.84L26.29 24l-8.58 8.58 2.82 2.84 8.6-8.6a4 4 0 0 0 0-5.64z" style="fill-rule:evenodd" data-name="1.-Foundations/Icons/ATAS_Icon_Arrow_Right_XSmall" /></svg>
</template>

<script>
export default {
    name: 'right-small',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
