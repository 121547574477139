<template>

    <svg :class="svgClass" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <g id="_1.-Foundations_Icons_ATAS_Icon_Account" data-name="1.-Foundations/Icons/ATAS_Icon_Account">
        <path id="Combined-Shape" class="cls-1" d="M8.22,29.72a35.46,35.46,0,0,1,31.56,0A4,4,0,0,1,42,33.3h0V42H38V33.3a31.48,31.48,0,0,0-28,0h0V38H30a4,4,0,0,1,4,4H6V33.3A4,4,0,0,1,8.22,29.72ZM24,6a8,8,0,1,1-8,8A8,8,0,0,1,24,6Zm0,4a4,4,0,1,0,4,4A4,4,0,0,0,24,10Z" />
      </g>
    </svg>

</template>

<script>
export default {
    name: 'person',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
