<template>
    <svg :class="svgClass" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 48 48">
<g id="Layer_1">
	<path class="st0" d="M24,32c2.1,0,4.2-0.8,5.7-2.3c1.5-1.5,2.3-3.5,2.3-5.7c0-1.5-0.4-2.9-1.1-4.1L41,9.8L38.2,7L28.1,17.1
		C26.9,16.4,25.5,16,24,16c-4.4,0-8,3.6-8,8c0,1.5,0.4,2.9,1.1,4L7,38.1L9.9,41l10.1-10.1C21.1,31.6,22.5,32,24,32z M28,24
		c0,2.2-1.8,4-4,4c-0.4,0-0.7-0.1-1-0.2l4.9-4.9C28,23.3,28,23.6,28,24z M20,24c0-2.2,1.8-4,4-4c0.4,0,0.7,0.1,1,0.1L20.2,25
		C20.1,24.7,20,24.3,20,24z M41.1,32.2c-0.9,1-1.8,1.9-2.8,2.8l-0.6,0.4c-3.9,3-8.7,4.6-13.7,4.6c-3.1,0-6-0.6-8.7-1.7l3.1-3.1
		C20.1,35.7,22,36,24,36c4.2,0.1,8.3-1.3,11.6-4C37.2,30.6,39.6,30.7,41.1,32.2z M8.5,33.8c-2.5-2.4-4.6-5.4-6.2-9L1.9,24l0.4-0.8
		C6.7,13.4,14.5,8,24,8c3,0,5.9,0.5,8.7,1.7l-3.1,3.1l-0.3-0.1C28.6,12.5,24.9,12,24,12c-9.7,0-14.9,6.3-17.7,12
		c1.2,2.4,2.8,4.9,5,7L8.5,33.8z M46.1,23.9l-0.3,0.8c-0.6,1.4-1.3,2.7-2.1,4c-1.3-4.5-3.7-8.5-6.9-11.8l2.7-2.7
		C42.3,16.9,44.6,20.2,46.1,23.9z" />
</g>
        <g id="Layer_2">
</g>
</svg>
</template>

<script>
export default {
    name: 'eye_strikeout',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
