<template>
<div>
    <div class="table-content position-relative " :class="wrapperClass">
        <div class="pb-3">
            <slot name="filters"></slot>

            <div class="position-absolute clear-state-above-filters d-flex" v-if="$parent.clearState || $parent.csvExport">
                <slot name="clear-state-above" v-if="$parent.clearState">
                </slot>
                <slot name="export-csv-above" v-if="$parent.csvExport">
                </slot>
            </div>
            <div class="position-relative" v-if="$parent.clearState || $parent.csvExport">
                <div class="position-absolute clear-state-below-filters d-flex">
                    <slot name="clear-state-below" />
                    <slot name="export-csv-below" v-if="$parent.csvExport" />
                </div>
            </div>
        </div>

        <table :class="tableClassesComputed" :id="tableId">
            <thead>
            <tr>
                <slot name="extra-columns-start"></slot>
                <th
                    v-for="(column, index) in columns"
                    :key="index"
                    :class="(column.sortable ? 'cursor-pointer ' : '') + (column.class ? ' ' + column.class : '')"
                    @click="column.sortable ? sort(column.data) : null"
                >
                        <span v-if="column.sortable">
                            <i :class="`text-primary fas fa-sort-${column.sortable}-up`" v-show="query.currentSort === column.data && query.currentSortDir === 'asc'"></i>
                            <i :class="`text-primary fas fa-sort-${column.sortable}-down`" v-show="query.currentSort === column.data && query.currentSortDir === 'desc'"></i>
                            <i class="text-primary fas fa-sort" v-show="query.currentSort !== column.data"></i>
                        </span>
                    {{ column.label }}
                </th>
                <slot name="extra-columns"></slot>
            </tr>
            </thead>
            <tbody>
            <slot name="data" :data="data"></slot>
            <slot name="loading">
                <tr v-show="loading && pagination.total === 0">
                    <td v-for="(column, index) in columns" :key="index"><span class="placeholder bg-light-gray col-4"></span></td>
                </tr>
            </slot>
            <slot name="no-records">
                <tr v-show="!loading && pagination.total === 0">
                    <td :colspan="columns.length + 1" class="p-2">No records matching the given filters.</td>
                </tr>
            </slot>
            </tbody>
        </table>
    </div>

    <div class="table-control-wrapper py-2 d-flex mb-5 pb-5 align-items-center">
        <slot name="pagination-info">
            <div class="col-6 col-md-2">
                <p class="f-12 p3" v-show="pagination.from > 0">{{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} {{ pagination.type }}</p>
            </div>
        </slot>

        <div class="col-6 col-md-3">
            <div class="table-num-per-page-wrapper d-flex justify-content-between align-items-center">
                <slot name="num-per-page">
                </slot>
            </div>
        </div>

        <slot name="pagination">
            <div class="col-12 col-md-7">
                <nav aria-label="Member search navigation" v-show="pagination.from > 0">
                    <ul class="pagination float-end">
                        <li class="page-item" @click.prevent="prevPage()" :class="{ disabled: !pagination.hasPrevPage }">
                            <a v-if="pagination.hasPrevPage" class="page-link previous-label" href="#">Previous</a>
                            <span v-else class="page-link previous-label">Previous</span>
                        </li>
                        <li
                            v-for="n in pagination.lastPage"
                            :key="n"
                            class="page-item"
                            :class="{ disabled: n === query.currentPage }"
                        >
                            <span v-if="shouldShowPageInPagination(n) === 'as_page'" @click.prevent="goToPage(n)">
                                <a v-if="n !== query.currentPage" class="page-link" href="#">{{ n }}</a>
                                <span v-else class="page-link current-page text-secondary">{{ n }}</span>
                            </span>
                            <span
                                v-else-if="shouldShowPageInPagination(n) === 'as_separator'"
                                class="page-link pagination-separator text-secondary"
                            >...</span>
                        </li>
                        <li class="page-item" @click.prevent="nextPage()" :class="{ disabled: !pagination.hasNextPage }">
                            <a v-if="pagination.hasNextPage" class="page-link next-label" href="#">Next</a>
                            <span v-else class="page-link next-label">Next</span>
                        </li>
                    </ul>
                </nav>
            </div>
        </slot>
    </div>
</div>
</template>

<script lang="ts">
export default {
    name: "DataTable",

    props: {
        columns: Array,
        data: Array,
        query: Object,
        pagination: Object,
        loading: {
            type: Boolean,
            default: false,
        },
        tableClass: {
            type: String,
            default: ''
        },
        tableId: {
            type: String,
            default: ''
        },
        wrapperClass: {
            type: String,
            default: ''
        },
        autoLayout: {
            type: Boolean,
            default: false
        }
    },

    emits: [
        "updatePage",
        "updateSort",
    ],

    data() {
        return {
            skippingPaginationElements: false,
            defaultSort: '',
            defaultSortDir: '',
        }
    },

    computed: {
        tableClassesComputed() {
            if (this.tableClass) {
                return this.tableClass;
            }

            const defaultClasses = 'table table-hover bg-white dataTable table-with-fixed-pagination';
            if (this.autoLayout) {
                return defaultClasses + ' dataTable-auto-layout';
            }
            return defaultClasses;
        }
    },
    mounted() {
        this.defaultSort = this.query.currentSort;
        this.defaultSortDir = this.query.currentSortDir;

        // A lot of components set their default value once they're mounted,
        // re-setting defaults here allows to catch those changes.
        this.$nextTick(() => {
            this.defaultSort = this.query.currentSort;
            this.defaultSortDir = this.query.currentSortDir;
        });
    },
    methods: {
        // Sorting cycle: asc -> desc -> default soft
        sort(column) {
            let direction = 'asc';
            if (this.query.currentSort === column) {
                if(this.query.currentSortDir === 'asc') {
                    direction = 'desc';
                } else if (this.query.currentSortDir === 'desc') {
                    column = this.defaultSort;
                    direction = this.defaultSortDir;
                }
            }

            this.$emit('updateSort', column, direction);
        },

        prevPage() {
            if (this.query.currentPage === 1) {
                return;
            }
            this.goToPage(this.query.currentPage - 1);
        },

        nextPage() {
            if (this.query.currentPage === this.pagination.lastPage) {
                return;
            }
            this.goToPage(this.query.currentPage + 1);
        },

        goToPage(n) {
            if (n === this.query.currentPage) {
                return;
            }
            this.$emit('updatePage', n);
        },

        // show the page number in the pagination list if:
        //  - the total number of pages is <= 10
        //  - the page number is within:
        //     - 3 pages from the start or end
        //     - a range of 5 pages from the current page (centred, unless we are close to the bounds)
        //  - it would prevent the ... from only separating one item
        // e.g. page 2 of 20 would show: 1 2 3 4 5 ... 18 19 20
        // e.g. page 8 of 20 would show: 1 2 3 ... 6 7 8 9 10 ... 18 19 20
        // e.g. page 7 of 13 would show: 1 2 3 4 5 6 7 8 9 10 11 12 13
        shouldShowPageInPagination(n: number): "as_page"|"as_separator"|false {
            const showPage = this.pagination.lastPage <= 10
                || Math.abs(this.query.currentPage - n) < Math.max(3, 5 - (this.query.currentPage - 1), 5 - (this.pagination.lastPage - this.query.currentPage))
                || Math.abs(this.pagination.lastPage - n) < 3
                || Math.abs(1 - n) < 3
                || this.query.currentPage === 7 && n === 4
                || this.query.currentPage === this.pagination.lastPage - 6 && n === this.pagination.lastPage - 3
            if (showPage) {
                return "as_page";
            }
            if (n === 4 || n === this.pagination.lastPage - 4) {
                return "as_separator";
            }
            return false;
        },
    }
}
</script>

<style scoped>

</style>
