<template>
    <div>
        <div role="alert" class="alert alert-danger" v-show=hideMessage>
            <div class="container">
                <div class="alert-header">
                    <span class="indicator-icon fa"></span>
                    <div class="message-wrapper">
                        <h2 v-text="message"  v-for="message in messages"></h2>
                    </div>
                    <span class="close-icon" @click.prevent="hideMessage = !hideMessage"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['messages'],
        data() {
            return {
                hideMessage: true
            }
        }
    }
</script>
