<template>
    <svg :class="svgClass" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M6 26v-4h32a4 4 0 0 1 4 4z" style="fill-rule:evenodd" data-name="1.-Foundations/Icons/ATAS_Icon_Button_Subtract" /></svg>

</template>

<script>
export default {
    name: 'minus-large',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
