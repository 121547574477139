<template>
    <svg :class="svgClass" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M23.42 4a20 20 0 1 1-12 35.6l2.84-2.9a16 16 0 1 0-4.42-5.2 4 4 0 0 1-1.34 5.18A20 20 0 0 1 23.42 4zM32 22v4H16v-4z" style="fill-rule:evenodd" data-name="1.-Foundations/Icons/ATAS_Icon_Button_Round_Substract" /></svg>


</template>

<script>
export default {
    name: 'minus-circle',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
