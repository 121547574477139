<!--

  This component was copied from the membership project.
  When fixing bugs or adding features, consider updating the original component as well.
  Until we have a shared library, we need to keep the components in sync manually.

  Original component is vue3+ts, compatibility changes for this project:
   - no TypeScript
   - @click.native instead of @click on the Icon components

-->
<template>
    <div
        :class="[
            'password-input-wrapper',
            maskToggle ? 'mask-toggle' : '',
            inputHasInvalidClass ? 'is-invalid' : '',
        ]"
    >
        <input
            v-bind="$attrs"
            :class="className"
            :type="inputType"
            :disabled="disabled"
            :required="required"
            v-model="value"
        >
        <Eye
            v-if="toggleButtonsVisible && !passwordVisible"
            svg-class="toggle-icon"
            role="button"
            @click.native="togglePasswordVisibility"
            aria-label="Show password"
        />
        <Eye_strikeout
            v-if="toggleButtonsVisible && passwordVisible"
            svg-class="toggle-icon"
            role="button"
            @click.native="togglePasswordVisibility"
            aria-label="Hide password"
        />
    </div>
</template>

<script>
import Eye_strikeout from "../../Icons/eye_strikeout.vue";
import Eye from "../../Icons/eye.vue";

export default {
    name: "PasswordInput",
    components: {Eye, Eye_strikeout},

    inheritAttrs: false,

    props: {
        maskToggle: {
            type: Boolean,
            default: false
        },
        className: {
            type: String,
            default: 'form-control'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: String,
        }
    },

    emits: [
        'update:modelValue'
    ],

    data() {
        return {
            localValue: this.modelValue,
            passwordVisible: false,
        }
    },

    computed: {
        inputType() {
            return this.maskToggle && this.passwordVisible && !this.disabled
                ? 'text'
                : 'password';
        },
        toggleButtonsVisible() {
            return this.maskToggle && !this.disabled;
        },
        inputHasInvalidClass() {
            return this.className.includes('is-invalid');
        },

        value: {
            get() {
                return this.localValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
                this.localValue = value;
            }
        },
    },

    watch: {
        modelValue(value) {
            this.localValue = value;
        }
    },

    methods: {
        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible;
        },
    }
}
</script>


<style scoped>
.password-input-wrapper {
    position: relative;
}

.password-input-wrapper.mask-toggle input {
    padding-right: 35px;
}

.password-input-wrapper.mask-toggle.is-invalid input {
    padding-right: 65px;
}

.password-input-wrapper .toggle-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.password-input-wrapper.is-invalid .toggle-icon {
    right: 40px;
}

</style>
