<template>
    <svg :class="svgClass" x="0px" y="0px" viewBox="0 0 48 48">
<g id="Layer_1">
</g>
        <g id="Layer_2">
	<path d="M38,15h-8v-5c0-2.21-1.79-4-4-4h-4c-2.21,0-4,1.79-4,4v5h-8c-2.21,0-4,1.79-4,4v15c2.21,0,4-1.79,4-4V19h8.14
		c0.45,1.72,2,3,3.86,3h4c1.86,0,3.41-1.28,3.86-3H38v19H6v4h32c2.21,0,4-1.79,4-4V19C42,16.79,40.21,15,38,15z M26,18h-4v-3v-5h4v5
		V18z M25,32h10v3H25V32z M35,30H25v-3h10V30z M17,29.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5
		S18.93,29.5,17,29.5z M15,31h4c2.21,0,4,1.79,4,4H11C11,32.79,12.79,31,15,31z" />
</g>
</svg>
</template>

<script>
export default {
    name: 'close',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
