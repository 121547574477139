<template>
    <div class="alert alert-info p-3 mt-3">
        <h2>Verify your bank account details</h2>

        <div v-if="microdepositType === 'descriptor_code'">
            To verify your bank account details, we have sent a small deposit to your bank account with a reference number.
            This may take 1-2 business days to arrive.
            Once it arrives, please enter the reference number below. The reference number is 6 characters long and starts with the letters "SM":
        </div>

        <div v-else>
            To verify your bank account details, we have sent two small deposits to your bank account.
            These may take 1-2 business days to arrive.
            Once they arrive, please enter the amounts below:
        </div>

        <form id="payment-form" @submit.prevent="verify">
            <div v-if="errors.length" class="alert alert-warning p-3 mt-3">
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </div>

            <template v-if="microdepositType === 'descriptor_code'">
                <div class="form-group clear-pull">
                    <label for="amount1">Reference number <em class="yellow no-style">*</em></label>
                    <input v-model="descriptorCode" type="text" name="descriptor_code" class="form-control" required>
                </div>
            </template>

            <template v-else>
                <div class="form-group clear-pull">
                    <label for="amount1">Amount 1 <em class="yellow no-style">*</em></label>
                    <input v-model="amount1" type="text" name="amount1" class="form-control" required>
                </div>

                <div class="form-group clear-pull">
                    <label for="amount1">Amount 2 <em class="yellow no-style">*</em></label>
                    <input v-model="amount2" type="text" name="amount1" class="form-control" required>
                </div>
            </template>

            <div class="form-group">
                <button id="card-submit" class="btn btn-primary btn-bigger-font btn-validate-checker btn-font-19" :disabled="processing || requiredFieldsAreMissing">
                    <i class="icon-check-circle"></i> Submit
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "VerifyMicrodeposits",
    props: {
        microdepositType: {
            type: String,
            required: true,
        },
        actionUrl: {
            type: String,
            required: true,
        },
        postPaymentUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            amount1: null,
            amount2: null,
            descriptorCode: null,
            errors: [],
            processing: false,
        }
    },
    computed: {
        requiredFieldsAreMissing() {
            if (this.microdepositType === 'descriptor_code') {
                return !this.descriptorCode;
            }

            return !this.amount1 || !this.amount2;
        },
    },
    methods: {
        verify() {
            this.processing = true;

            axios.post(this.actionUrl, {
                descriptor_code: this.descriptorCode,
                amounts: [
                    this.amount1,
                    this.amount2,
                ],
            }).then((response) => {
                this.processing = false;

                if (response.data.success) {
                    window.location.replace(this.postPaymentUrl);
                } else {
                    this.errors = response.data.errors;
                }
            }).catch((error) => {
                this.processing = false;
                this.errors = error.response.data.errors;
            });
        },
    },
}
</script>
