<template>
    <div class="">
        <table class="no-hover" id="sortable-table">
            <thead>
            <tr class="tableHeaderRow">
                <th class="entry th-entry pl-2">Order #</th>
                <th>Category</th>

                <th>Type</th>
                <th>Network</th>
                <th>Program</th>
                <th class="entry th-entry" style="width:80px;">Cost</th>
                <th class="clickable" style="width:120px;"> <div class="pull-right">
                    <span class="selectall" v-if="selectToggle" @click="selectAll">Select All</span><span class="unselectall "  v-if="!selectToggle"  @click="unselectAll">Unselect All</span>
                </div></th>
            </tr>
            </thead>
            <tbody>

            <tr class="entry" v-for="(order, i) in orders">

                <td class="pl-2"><div>{{ order.order_id }}</div></td>
                <td><div>{{ order.category_name }}</div></td>

                <td><div>{{ order.order_type }}</div></td>
                <td><div>{{ order.network }}</div></td>
                <td><div>{{ order.program }}</div></td>
                <td class="cost">
                    <div><b><span>{{ formatPrice(order.cost) }}</span></b></div>
                </td>
                <td class="checkboxes">
                    <div class="checkout-checkbox-wrapper pull-right">
                        <input type="checkbox" :id="'order_ids_'+order.order_id" class="checkbox" :data-cost="order.cost" name="order_ids[]" v-model="checkedOrders" :value="order.order_id" @click="">
                        <label class="box-checking pl-0" :for="'order_ids_'+ order.order_id ">
                            <checkbox_checked class="svg-24 svg-accent" v-if="checkedOrders && checkedOrders[i]"></checkbox_checked>
                            <checkbox_empty class="svg-24 svg-accent" v-else></checkbox_empty>
                        </label>
                    </div>
                </td>
            </tr>

            </tbody>
            <tfoot>
            <tr class="summary">
                <td colspan="4"><div></div></td>
                <td colspan="2"><div class="pull-right total-due">Total Due Now &nbsp;</div></td>

                <td colspan="1"><div class="total-due"> <span>{{ formatPrice(totalCost) }}</span></div></td>
            </tr>
            </tfoot>
        </table>
        <button type="submit" class="btn btn-primary btn-bigger-font pull-right" v-show="checkedOrders.length > 0">Confirm And Pay</button>
    </div>
</template>

<script>
    import Checkbox_empty from "@/Icons/checkbox_empty.vue";
    import Checkbox_checked from "@/Icons/checkbox_checked.vue";

    export default {
        components: {Checkbox_checked, Checkbox_empty},
        props: ['orders'],
        data() {
            return {
                checkedOrders: [],
                totalCost: 0,
                orderCosts: [],
                selectToggle: true,
            }
        },
        mounted() {

        },
        methods: {
            calculateTotal: function(e) {
                // $('.checkout-table tr').each(function(el,i) {
                //     if($(this).find('.checkbox').is(':checked')) {
                //         var costVal = parseFloat($(this).find('.cost span').text().replace(/,/g, ''));
                //         totalCost += costVal;
                //     }
                // });
            },
            selectAll: function() {
                this.checkedOrders = this.orders.map((x)=>{ return(x.order_id)});
                this.selectToggle = false;
            },
            unselectAll: function() {
                this.checkedOrders = [];
                this.selectToggle = true;
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return '$'+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
        },
        watch: {
            checkedOrders: function (val) {
                var app = this;
                app.totalCost = 0;

                this.orderCosts = this.orders.map(function (value, label) {
                    if(app.checkedOrders.includes(value.order_id)) {
                        app.totalCost += value.cost;
                    }
                });
            }
        }
    }
</script>
