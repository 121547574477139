<template>
<div class="datatable-wrapper">
    <div class="row mt-4 filters">
        <div class="col-md-3" v-for="f in filters" :key="f.name">
            <div class="form-group mb-3">
                <label :for="'filter-input-' + f.name">
                    {{ f.label }} <span v-if="f.required" class="text-danger">*</span>
                </label>
                <v-select
                    class="pt-0"
                    v-if="f.type === 'custom_select'"
                    :id="'filter-input-' + f.name"
                    v-model="filter[f.name]"
                    :options="f.options"
                    :multiple="f.multi"
                    :reduce="o => o.value"
                    :clearable="f.clearable !== false"
                    :placeholder="f.placeholder"
                />
                <input
                    v-if="f.type === 'text'"
                    :id="'filter-input-' + f.name"
                    v-model="filter[f.name]"
                    class="form-control"
                >
            </div>
        </div>
    </div>

    <hr class="mt-4" v-if="reportRan">

    <div class="mt-4" v-if="reportRan">
        <div class="d-flex w-100">
            <div class="flex-grow-1">
                <h2>Results</h2>
            </div>
            <div class="flex-shrink-0 text-right">
                <a class="btn mr-3" href="#" @click.prevent="setDefaults(true)">
                    <close-icon class="svg-16"></close-icon> Clear Filters
                </a>
                <a class="btn" :href="downloadUrlFiltered">
                    <download class="svg-black mr-2 svg-16"></download> Export to CSV
                </a>
            </div>
        </div>

        <data-table
            :data="rows"
            :pagination="pagination"
            :query="query"
            :columns="columns"
            :loading="loading"
            @updatePage="updatePage"
            @updateSort="updateSort">
            <template #data="{data}">
                <tr v-for="(row) in data">
                    <td v-for="(column) in columns" class="p-2 text-break">
                        <div>
                            <template v-if="column.type === 'link'">
                                <a :href="row[column.urlField]">{{ row[column.data] }}</a>
                            </template>
                            <template v-else-if="column.type === 'html'">
                                <span v-html="row[column.data]"></span>
                            </template>
                            <template v-else>
                                {{ row[column.data] }}
                            </template>
                        </div>
                    </td>
                </tr>
            </template>
        </data-table>
    </div>
</div>
</template>

<script lang="ts">
import DataTable from "../DataTable.vue";
import axios from "axios";
import _ from "lodash";
import { format } from "date-fns";
import CloseIcon from "../../Icons/close.vue";
import Download from "../../Icons/download.vue";

export default {
    name: "TabularReport",
    components: {
        Download,
        CloseIcon,
        DataTable,
    },
    props: {
        filters: {
            type: Array,
            required: true
        },
        executeUrl: {
            type: String,
            required: true
        },
        downloadUrl: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            rows: [],
            columns: [],
            loading: false,
            reportRan: false,
            filter: {},
            query: {
                currentSort: null,
                currentSortDir: null,
                perPage: 20,
                currentPage: null,
            },
            pagination: {
                hasPrevPage: false,
                hasNextPage: false,
                lastPage: 1,
                from: 0,
                to: 0,
                total: 0,
            },
        }
    },

    computed: {
        downloadUrlFiltered() {
            return this.downloadUrl + '?' + new URLSearchParams(this.buildFilterQueryParams()).toString();
        },
        requiredFiltersMissing() {
            return this.filters.filter((filter) => {
                if (!filter.required) {
                    return false;
                }
                if (filter.type === 'date_range') {
                    return !this.filter[filter.name + '_from'] || !this.filter[filter.name + '_to'];
                }
                return !this.filter[filter.name];
            }).length > 0;
        }
    },

    mounted() {
        if (Object.keys(this.filter).length === 0) {
            this.setDefaults();
        }

        if (!this.requiredFiltersMissing) {
            this.runReportDebounced();
        }
    },

    beforeUnmount() {
    },

    watch: {
        filter: {
            handler: function () {
                this.runReportDebounced();
            },
            deep: true,
        }
    },

    methods: {
        setDefaults(rerunReport = false) {
            for (const filter of this.filters) {
                if (filter.default) {
                    this.filter[filter.name] = filter.default;
                } else {
                    delete this.filter[filter.name];
                }
            }

            if (rerunReport) {
                this.runReport();
            }
        },

        runReportDebounced: _.debounce(function () {
            this.runReport();
        }, 500),

        runReport(page = 1) {
            console.log('running report');
            if (this.requiredFiltersMissing) {
                this.loading = false;
                this.reportRan = false;
                return;
            }
            this.loading = true;
            this.reportRan = true;
            axios.get(this.executeUrl, {
                params: {
                    ...this.buildFilterQueryParams(),
                    page: page,
                    per_page: this.query.perPage,
                    sort: this.query.currentSort,
                    direction: this.query.currentSortDir,
                }
            })
                .then(response => {
                    this.columns = response.data.columns;

                    this.rows = response.data.page.data;
                    this.query.currentPage = response.data.page.current_page;
                    this.pagination.from = response.data.page.from;
                    this.pagination.to = response.data.page.to;
                    this.pagination.total = response.data.page.total;
                    this.pagination.hasPrevPage = !!response.data.page.prev_page_url;
                    this.pagination.hasNextPage = !!response.data.page.next_page_url;
                    this.pagination.lastPage = response.data.page.last_page;

                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                })
        },

        buildFilterQueryParams() {
            const params = {};
            for (const filter of this.filters) {
                if (filter.type === 'date_range') {
                    params[filter.name + '_from'] = this.filter[filter.name + '_from']
                        ? format(new Date(this.filter[filter.name + '_from']), 'yyyy-MM-dd')
                        : undefined;
                    params[filter.name + '_to'] = this.filter[filter.name + '_to']
                        ? format(new Date(this.filter[filter.name + '_to']), 'yyyy-MM-dd')
                        : undefined;
                    continue;
                } else if (filter.type === 'date_year') {
                    params[filter.name] = this.filter[filter.name]
                        ? format(new Date(this.filter[filter.name]), 'yyyy')
                        : undefined;
                    continue;
                } else if (filter.type === 'date_month') {
                    params[filter.name] = this.filter[filter.name]
                        ? format(new Date(this.filter[filter.name]), 'yyyy-MM')
                        : undefined;
                    continue;
                } else if (filter.type === 'date_day') {
                    params[filter.name] = this.filter[filter.name]
                        ? format(new Date(this.filter[filter.name]), 'yyyy-MM-dd')
                        : undefined;
                    continue;
                }

                if (Array.isArray(this.filter[filter.name])) {
                    params[filter.name] = this.filter[filter.name].join(',');
                } else {
                    params[filter.name] = this.filter[filter.name];
                }
            }

            // remove empty values
            Object.keys(params)
                .forEach(key => {
                    if (typeof params[key] === 'undefined' || params[key] === null) {
                        delete params[key];
                    }
                });

            return params;
        },

        updatePage(n) {
            this.runReport(n);
        },
        updateSort(column, direction) {
            this.query.currentSort = column;
            this.query.currentSortDir = direction;
            this.runReport();
        },
    }
};
</script>
