<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
        <g id="Layer_1">
            <g>
                <path d="M35.6,14l-2-8H14.4l-2,8H6v4h36v-4H35.6z M17.6,10h12.9l1,4H16.6L17.6,10z" />
                <path d="M10,34.6l4.9,7.4H38v0c0-2.2-1.8-4-4-4h-4V24h4v10.1h4V20H14c-2.2,0-4,1.8-4,4V34.6z M26,38h-4V24h4V38z M14,24h4v14h-0.9L14,33.4V24z" />
            </g>
        </g>
        <g id="Layer_2"></g>
    </svg>
</template>

<script>
export default {
    name: 'DeleteIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
