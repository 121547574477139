<template>
    <div class="payment-options-container">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>


        <div> {{ payment_status }} </div>
        <div class="row pt-4" v-if="payment_enabled">

            <div class="col-3 offset-9 text-center">
                <a v-if="invoice.contact && invoice.status === 'unpaid'" :href="'/invoice/download/'+invoice.id+'/pdf'" class="btn btn-primary width-100 mb-2"><download class="svg-black svg-16"></download> Download invoice</a>
                <div><a  @click.prevent="showCC('bank_transfer')" class="btn btn-primary width-100"><strong>Pay with bank transfer</strong></a></div>
                <p class="f-14 font-italic">Preferred Method</p>
                <a v-if="invoice.amount_due > 1000" @click.prevent="showEmail" class="btn btn-blue width-100 p-2">Email Invoice</a>
                <a @click.prevent="showCC('card')" class="btn btn-blue width-100 p-2 mt-2">Pay with Credit Card</a>
            </div>

        </div>

        <modal v-show="activeCC" @close="showCC('close')">
            <h3 slot="header">{{ modal_heading }}</h3>
            <div class="content" slot="body">
                <p v-show="!paymentElementLoaded" class="text-muted">
                    Payment form loading...
                </p>

                <form id="payment-form" @submit="confirmPurchase" v-show="paymentElementLoaded">
                    <div v-if="errors.length">
                        <div>
                            <p><b>Please correct the following error(s):</b></p>
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </div>
                    </div>

<!--                    <div class="form-group clear-pull">-->
<!--                        <label for="cardName">Cardholder Name <em class="yellow no-style">*</em></label>-->
<!--                        <input type="text" name="cardName" id="cardName" class="form-control"-->
<!--                               v-model="cardName" data-ref="cardName"  required="required">-->
<!--                    </div>-->

                    <div ref="pElement"></div>

                </form>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn-black" @click="showCC('close')">Cancel</button>
                <button class="btn btn-primary pull-right" :disabled="!paymentElementLoaded || payment_processing" @click.prevent="confirmPurchase">Submit Payment</button>
            </div>
        </modal>

        <modal v-show="activeEmail" @close="showEmail">
            <h3 slot="header">Email Invoice to a Billing Contact</h3>
            <div class="content" slot="body">
                <div class="payment-form-wrapper" v-show="activeEmail">

                    <form method="POST" :action="'/invoice/'+invoice.id+'/email'" id="invoiceForm" class="submit-once" @submit="sendInvoiceInfo">

                        <div v-if="errors.length">
                            <div>
                                <p><b>Please correct the following error(s):</b></p>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group-beside clear-pull row">
                            <label for="cardName">Billing Contact Name <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input type="text" name="cardName" id="cardName2" class="form-control"
                                       v-model="cardName" data-ref="cardName" required="required">
                            </div>
                        </div>

                        <div class="form-group-beside clear-pull row">
                            <label for="company">Billing Company</label>
                            <div class="col-10 col-md-6 input-wrapper">
                            <input type="text" name="company" id="company" class="form-control"
                                   v-model="company" data-ref="company">
                            </div>
                        </div>

                        <div class="form-group-beside form-50 row">
                            <label for="address">Billing Address <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                            <input type="text" name="address" id="address2" class="form-control"
                                   required v-model="address">
                            </div>
                        </div>

                        <div class="form-group-beside form-50 row">
                            <label for="city">City <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                            <input type="text" name="city" id="city2" class="form-control"
                                   required="required" v-model="city">
                            </div>
                        </div>

                        <div class="form-group-beside form-50 row">
                            <label for="state">State/Province:</label>
                            <div class="col-10 col-md-6 input-wrapper">
                            <region-select v-model="state" :country="country" :region="state" name="state" />
                            </div>
                        </div>

                        <div class="form-group-beside form-50 row">
                            <label for="zip">Zip/Postal Code: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                            <input type="text"   maxlength="15" name="zip" id="zip2" class="form-control"
                                   required="required" v-model="zip">
                            </div>
                        </div>

                        <div class="form-group-beside form-50 row">
                            <label for="country">Country (if other than USA):</label>
                            <div class="col-10 col-md-6 input-wrapper">
                            <country-select v-model="country" :country="country" topCountry="US" name="country" />
                            </div>
                        </div>

                        <div class="form-group-beside form-50 row">
                            <label for="po_number">PO Number: </label>
                            <div class="col-10 col-md-6 input-wrapper">
                            <input type="text"   maxlength="20" name="po_number" id="po_number" class="form-control"
                                   v-model="po_number">
                            </div>
                        </div>

                        <div class="form-group-beside row">
                            <label for="recipients">Enter the email address you want the invoice sent to: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                            <input type="text" name="recipients" id="recipients" class="form-control"
                                   v-model="recipients" required="required">
                            </div>
                        </div>

                        <div class="row" >
                            <input  type="hidden" v-model="csrf" name="_token" />
                            <div class="col-6">
                                <button class="pull-left btn-black" @click="showEmail">Cancel</button>
                            </div>
                            <div class="col-6 pull-right align-right">
                                <button type="submit" name="payment_type" value="invoice" class="btn btn-primary pull-right" >Send Invoice</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <div  slot="footer">
            </div>
        </modal>



        <modal v-if="errorModal" @close="reload">
            <h3 slot="header">Error</h3>
            <div class="content" slot="body">
                {{ errorMessage }}
                <p>Please try again or contact the Television Academy at 818.754.2881.</p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left" @click="reload">Close</button>
            </div>
        </modal>

    </div>
</template>

<script>
    // Import component
    import loading from 'vue-loading-overlay';
    import modal from '../components/Modal.vue';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Download from "@/Icons/download.vue";

    export default {
        name: "InvoicePayment",
        props: {
            invoice: {
                type: Object,
                required: true,
            },
            actionUrl: {
                type: String,
                required: true,
            },
            postPaymentUrl: {
                type: String,
                required: true,
            },
        },
        components: {
            Download,
            loading,
            modal
        },
        data() {
            return {
                stripe: Stripe(window.stripeKey),
                errorMessage: '',
                errorModal: false,
                hasCardErrors: false,
                isLoading: false,
                fullPage: true,
                csrf:window.Laravel.csrfToken,
                greyCC: '',
                greyEmail: '',
                activeEmail: false,
                activeCC: false,
                errors: [],
                totalCost: 0,
                token: '',
                orderCosts: [],
                recipients: '',
                po_number: '',
                cardName: "",
                company: '',
                country: 'US',
                state: '',
                zip:'',
                city:'',
                address: '',
                paymentElementLoaded: false,
                payment_processing: false,
                client_secret: null,
                elements: null,
                card: null,
                payment_enabled: false,
                payment_status: '',
                modal_heading: '',
            }
        },
        mounted() {

          let cardNumber = undefined;
          let cardCvc = undefined;
          let cardExpiry = undefined;
          let elementStyles = {
            iconStyle: "solid",
            base: {

              iconColor: '#737373',
              color: '#737373',
              fontWeight: 500,
              fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
              fontSize: '16px',
              border: '1px solid red',
              fontSmoothing: 'antialiased',
              ':-webkit-autofill': {
                color: '#fce883',
              },
              '::placeholder': {
                color: '#CFD7DF',
              },
            },
            invalid: {
              // All of the error styles go inside of here.
              iconColor: '#ff0000',
              color: '#ff0000',

            }

          };
            var elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid',
            };


            if(this.invoice.contact) {
                //console.log(this.invoice.contact.name);
                // this.invoice.contact = JSON.parse(this.invoice.contact);

                this.cardName = this.invoice.contact.name;
                this.company = this.invoice.contact.company;
                this.address = this.invoice.contact.address;
                this.city = this.invoice.contact.city;
                this.state = this.invoice.contact.state;
                this.zip = this.invoice.contact.zip;
                this.country = this.invoice.contact.country;
            }
            if (this.invoice.email) {
                this.recipients = this.invoice.email;
            }
            if (this.invoice.po_number) {
                this.po_number = this.invoice.po_number;
            }

            // parses the stripe intent from the URL
            this.confirmStripePaymentIntent();
        },
        methods: {
            loadStripElements (payment_method) {
                console.log("Loading Stripe Elements")
                let app = this;
                app.paymentElementLoaded = false;
                axios.post(app.actionUrl, { payment_method })
                    .then(response => {
                        //console.log(response.data.client_secret);
                        app.client_secret = response.data.client_secret;
                        console.log('client_secret returned');

                        const styleOptions = {
                        };

                        const options = {
                            clientSecret: app.client_secret,
                            // Fully customizable with appearance API.
                            appearance: styleOptions,
                        };

                        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
                        app.elements = app.stripe.elements(options);

                        // Create and mount the Payment Element=
                        const paymentElement = app.elements.create('payment');
                        paymentElement.on('ready', () => {
                            this.paymentElementLoaded = true;
                            this.$nextTick(() => paymentElement.focus());
                        });

                        paymentElement.mount(this.$refs.pElement);
                    })
                    .catch(err => {
                        console.log(err);
                        let errorMessage = 'Could not load credit card payment form. Please try again.';
                        this.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    });

            },
            reload: function() {
                this.errorModal = false;
            },
            showEmail: function() {
                if (this.activeEmail) {
                    this.greyCC = '';
                } else {
                    this.greyCC = 'disable';
                    this.greyEmail = '';
                }
                this.activeCC = false;
                this.activeEmail = !this.activeEmail;
            },
            showCC: function(payment_method) {
                if (payment_method !== 'close') {
                    this.loadStripElements(payment_method);
                    this.modal_heading = (payment_method === 'card') ? 'Pay With Credit Card' : 'Pay With Bank Transfer';
                }
                if (this.activeCC) {
                    this.greyEmail = '';
                } else {
                    this.greyCC = '';
                    this.greyEmail = 'disable';
                }
                this.activeEmail = false;
                this.activeCC = !this.activeCC;
            },
            async confirmPurchase(e) {
                console.log('confirming Purchase')
                e.preventDefault();
                let app = this;
                app.payment_processing = true;

                const {error} = await app.stripe.confirmPayment({
                    //`Elements` instance that was used to create the Payment Element
                    elements: app.elements,
                    confirmParams: {
                        return_url: app.postPaymentUrl,
                    },
                });
                if (error) {
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Show error to your customer (for example, payment
                    // details incomplete)

                    app.payment_processing = false;
                    this.$root.$refs.flash_alert.activateFlashAlert(error.message, 'error');
                    app.$refs["error-message"].textContent = error.message;
                } else {
                    // Your customer will be redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer will be redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                }

            },
            confirmStripePaymentIntent() {
                let app = this;

                app.client_secret = new URLSearchParams(window.location.search).get(
                    'payment_intent_client_secret'
                );
                if (app.client_secret) {
                    app.stripe.retrievePaymentIntent(app.client_secret).then(({paymentIntent}) => {
                        // Inspect the PaymentIntent `status` to indicate the status of the payment
                        // to your customer.
                        //
                        // Some payment methods will [immediately succeed or fail][0] upon
                        // confirmation, while others will first enter a `processing` state.
                        //
                        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                        switch (paymentIntent.status) {
                            case 'succeeded':
                                app.payment_status = 'Success! Payment received.';
                                break;

                            case 'processing':
                                app.payment_status = "Payment processing. We'll update you when payment is received.";
                                break;

                            case 'requires_payment_method':
                                app.payment_status = 'Payment failed. Please try another payment method.';
                                // Redirect your user back to your payment page to attempt collecting
                                // payment again
                                break;

                            default:
                                app.payment_status = 'Something went wrong.';
                                break;
                        }
                    });
                } else {
                    app.payment_enabled = true;
                }
            },
            sendInvoiceInfo: function(e) {
                let app = this;
                app.isLoading = true;

                let data = {
                    isajax : 'true',
                    cardName: app.cardName,
                    company: app.company,
                    country: app.country,
                    state: app.state,
                    zip: app.zip,
                    city: app.city,
                    address: app.address,
                    recipients: app.recipients,
                    po_number: app.po_number,
                    payment_type: 'invoice'
                };
                axios.post('/invoice/'+app.invoice.id+'/email', data)
                    .then(res => {
                        app.$root.$refs.flash_alert.activateFlashAlert("Invoice Sent!", 'success');
                        app.isLoading = false;
                        app.activeEmail = false;
                        //console.log('form submitted', res.data)
                        //window.location.replace('/orders?payment=invoice');
                    }).catch(err => {
                        //console.log(err)
                        app.isLoading = false;
                        app.errorModal = true;
                        app.errorMessage = 'There was a problem sending your invoice. Please try again.';
                    });
                e.preventDefault();
            }
        },

    }
</script>

<style>
.StripeElement {
    width: 100%;
    display: block;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 0;
    border-right: 0;
    border-radius: 0;
    box-shadow: none;
}
.modal-body {
    border:none;
    margin:0;
}

</style>
