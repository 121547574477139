<template>
    <div>
        <div v-if="!loaded">Generating video preview. This may take a while. Check back later.</div>
        <div class="justify-content-center"  v-if="loaded">
            <a href="#" @click.prevent="modalTriggerOpenModal()" class="btn btn-primary">
                <play-icon class="svg-black"></play-icon>
                Preview
            </a>
            <modal v-show="modalTriggerValue" @close="modalTriggerCloseModal()" modalclass="modal-videofile" clickoutside="true">
                <div slot="header" class="d-flex justify-content-between full-width">
                    <div>
                        <h3 class="mb-0">{{episode}}</h3>
                        <template v-if="role == 'admin'">Brightcove ID: {{ asset_id }}</template>
                    </div>

                    <div>
                        <i class="close-icon" @click="modalTriggerCloseModal()"></i>
                    </div>
                </div>
                <div class="content" slot="body">
                    <div :id="'player'+vfoid"></div>
                    {{ filename }}
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import modal from './Modal.vue';
import brightcovePlayerLoader from '@brightcove/player-loader';
import PlayIcon from "@/Icons/play.vue";

export default {
    name: "Videofile",
    props: ['vfoid', 'episode', 'filename','asset_id', 'role'],
    components: {PlayIcon, modal},
    data() {
        return {
            player: '',
            modalTriggerValue: false,
            videoPlaying: false,
            loaded: false,
            myPlayer: null,
            playerId: null,
            // +++ Set the data for the player +++
            playerData: {
                accountId: "1483464688001",
                playerId: "default",
                videoId: this.asset_id
            }
        }
    },
    created() {
        console.log('created vfoid ',this.asset_id);
    },
    mounted() {
        let app = this;
        // load the brightcove player
        console.log('mounted vfoid ',this.vfoid);
        app.playerId = 'player'+app.vfoid;
        //app.playerHTML ='<video-js id="'+app.playerId+'"  data-account="1483464688001" data-player="default" data-embed="default" controls="" data-video-id="6315182647112" data-playlist-id="" data-application-id="" ></video-js>';
            // '<video-js id="'+app.playerId+'" data-video-id="' +
            // app.playerData.videoId +
            // '"  data-account="' +
            // app.playerData.accountId +
            // '" data-player="' +
            // app.playerData.playerId +
            // '" data-embed="default" controls></video-js> \n'


        app.loaded = true;

    },
    watch: {
        modalTrigger: function (val) {
            this.loadVids();
        }
    },
    methods : {
        modalTriggerOpenModal: function() {
            this.modalTriggerValue = true;
            this.loadVids();
        },
        modalTriggerCloseModal: function() {
            this.myPlayer.pause();
            this.modalTriggerValue = false;
        },
        loadVids: function() {
            let app = this;

            brightcovePlayerLoader({
                refNode: document.querySelector('#'+app.playerId),
                refNodeInsert: 'replace',
                accountId: app.playerData.accountId,
                playerId: app.playerData.playerId,
                embedId: 'default',
                videoId: app.asset_id
            })
                .then(function(success) {
                    // The player has been created!
                    app.myPlayer = success.ref;
                    console.log('success', success);
                    // myPlayer.on('loadedmetadata',function(){
                    //     myPlayer.muted(true);
                    //     myPlayer.play();
                    // });
                })
                .catch(function(error) {
                    // Player creation failed!
                    console.log(error);
                });
        }
    }
}
</script>
<style lang="scss">
.bmpui-ui-watermark {
    display:none;
}
.bmpui-ui-recommendation-overlay {

}
.video-js {
    width:100%; height: 650px; display:block;
}
</style>
