<template>
    <div class="fullwidth">
        <div v-if="showMessage" role="alert" class="flash-alert mb-4" :class="alertClass">
            <div class="alert-header">
                <AttentionIcon class="white-svg mx-4" />
                <div class="message-wrapper">
                    <h4 class="mb-0 text-white" v-html="displayMessage"></h4>
                </div>
                <span class="d-inline-block white-svg cursor-pointer flex-grow-1 text-end" @click.prevent="showMessage = false">
                    <circle-close-icon />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import AttentionIcon from "@/Icons/attention.vue";
import CircleCloseIcon from "@/Icons/circle_close.vue";

export default {
    components: {
        CircleCloseIcon,
        AttentionIcon,
    },
    props: {
        'message': {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            showMessage: !!this.message?.message,
            alertClass: 'alert-' + (this.message?.level ?? 'info'),
            displayMessage: this.message?.message
        }
    },
    methods: {
        activateFlashAlert(messageText, alertLevel) {
            this.showMessage = true;
            this.displayMessage = messageText;
            this.alertClass = 'alert-' + (alertLevel ?? 'info');

            if (alertLevel === 'success') {
                setTimeout(function() {
                    this.showMessage = false
                }, 6000)
            }
        },
        closeFlashAlert() {
            this.showMessage = false;
        }
    }
}
</script>
