<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
        <g id="Layer_1">
            <path d="M34,20l0-3.6c0-5.3-4-10-9.3-10.3C18.9,5.6,14,10.3,14,16v4H8v14h0c2.2,0,4-1.8,4-4v-6h24v14H8v4h32V24c0-2.2-1.8-4-4-4H34z M18,16c0-3.3,2.7-6,6-6s6,2.7,6,6v4H18V16z" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'LockIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
