<template>

    <svg  :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M6 38h36v4H6zm20-4h-4V12.8l-8.6 8.6-2.8-2.8 12-12C23.8 5.3 26 6.2 26 8v26zm11.4-15.4L30 11.2h0a3.98 3.98 0 0 0 0 5.7l4.6 4.6 2.8-2.9z" /></svg>
</template>

<script>
export default {
    name: 'upload',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>


