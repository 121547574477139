<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path d="M4.7 41.3V30.6l12.7 12.7H6.7c-1.1 0-2-.9-2-2zm25.4-16.4L17.4 37.7l2.8 2.8L33 27.8l-2.9-2.9zm-7-12.7L7.5 27.8l2.8 2.8L23 17.9l4.2 4.2a3.98 3.98 0 0 0 5.7 0h0l-9.8-9.9zm19.1.7l-7.1-7.1a3.98 3.98 0 0 0-5.7 0l-3.5 3.5 2.8 2.8 3.5-3.5 7.1 7.1-3.5 3.5 2.8 2.8 3.5-3.5c1.6-1.5 1.6-4 .1-5.6z" />
    </svg>
</template>


<script>
export default {
    name: 'EditIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

