<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M14.64 6.32a20 20 0 1 1-3.2 33.24l2.84-2.88a16 16 0 1 0-4.42-5.2 4 4 0 0 1-1.32 5.18 18.87 18.87 0 0 1-2.22-3.3 20 20 0 0 1 8.32-27.04zM24 30a3 3 0 1 1-3 3 3 3 0 0 1 3-3zm2-18a6 6 0 0 1 0 12v4h-4v-4a4 4 0 0 1 4-4 2 2 0 0 0 0-4h-4a2 2 0 0 0-2 2v2h-4v-2a6 6 0 0 1 6-6h4z" style="fill-rule:evenodd" data-name="1.-Foundations/Icons/ATAS_Icon_Warning" /></svg>
</template>

<script>
export default {
    name: 'question',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
