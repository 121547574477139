<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="m28.58 19.07 10.84 10.82-2.84 2.82-8-8a4 4 0 0 1 0-5.64zM24 15.29a2 2 0 0 1 1.42.6 2 2 0 0 1 0 2.82l-14 14-2.84-2.82 14-14a2 2 0 0 1 1.42-.6z" style="fill-rule:evenodd" data-name="1.-Foundations/Icons/ATAS_Icon_Arrow_Up_Small" /></svg>
</template>

<script>
export default {
    name: 'UpChevronIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

