<template>

    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 48 48">
        <g >
	<g id="ATAS_Icon_Email_Help_00000100383282560546189960000009848521938604935357_">
		<g id="Group_00000173856577527295932690000013128811127761932471_" transform="translate(2.000000, 4.000000)">
			<path id="Shape_00000035497974448402618750000001876685463930340268_" class="st0" d="M4,9.6v20.8c0,2.2,1.8,4,4,4h24.8
				c0-2-1.6-3.6-3.6-3.6H7.6V11.7l4.9,4.9c1,1,2.4,1.6,3.8,1.6h11.4c1.4,0,2.8-0.6,3.8-1.6l4.9-4.9v22.7H40V9.6c0-2.2-1.8-4-4-4H8
				C5.8,5.6,4,7.4,4,9.6z M29,14.1c-0.3,0.3-0.8,0.5-1.3,0.5H16.3c-0.5,0-0.9-0.2-1.3-0.5l-4.9-4.9h23.7L29,14.1z" />
		</g>
	</g>
</g>
        <g id="Layer_2">
</g>
</svg>

</template>

<script>
export default {
    name: 'email-icon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

