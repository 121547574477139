<template>
    <div>
        <div @click="collapsed = !collapsed" class="cursor-pointer profile-accordion border-bottom-1-grey6 d-flex align-items-center justify-content-between mb-3 toggle-with-up-icon text-decoration-none">
            <h3 class="primary mb-0">{{ title }}</h3>
            <down-chevron-icon class="float-end svg-24 svg-accent" v-if="collapsed"></down-chevron-icon>
            <up-chevron-icon class="float-end svg-24 svg-accent" v-else></up-chevron-icon>
        </div>

        <div class="collapse show profile-info-wrapper mb-4" id="collapseAboutEvent" v-show="!collapsed">
            <span class="btn btn-primary uppercase btn-no-hover no-click font-weight-bold f-14 w-200px" @click.prevent="" v-if="type == 'Virtual'">Member RSVP</span>
            <span class="btn btn-primary uppercase btn-no-hover no-click font-weight-bold f-14 w-200px" @click.prevent="" v-else>Enter the Lottery</span>
        </div>
    </div>
</template>

<script>
import UpChevronIcon from "@/Icons/up-chevron.vue";
import DownChevronIcon from "@/Icons/down_chevron.vue";

export default {
    components: {
        DownChevronIcon,
        UpChevronIcon
    },
    props: {
        title: String,
        livestream: {
            type: Boolean,
            default: false
        },
        type: String,
    },
    data() {
        return {
            collapsed: false
        }
    }
}

</script>
