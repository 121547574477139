<template>
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <g>
	        <path d="M25.8,6H13c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h18v0c0-2.2-1.8-4-4-4H13V10h10v12h12v20h4V19.2L25.8,6z M27,12.8l5.2,5.2H27V12.8z" />
        </g>
        <g id="Layer_2"></g>
    </svg>
</template>

<script>
export default {
    name: 'FileIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
